import React from "react";
import sharedImports from "../../../Comman/sharedImports";
import { useSnackbar } from "notistack";
import { getAllInputJobDetailData } from "../../../../api/user";
import { UploadEmployeeDocument, DeleteEmployeeDocument } from "api/employee";
import { Radio, RadioGroup } from "@mui/material";
import styles from "./style";
import { SendGetRequest } from "api/config/ApiCall";
import PaginationControls from "../../../Comman/paginationControls";
const {
  Box,
  Grid,
  Stack,
  Slide,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  PropTypes,
  useMediaQuery,
  TableCell,
  TableContainer,
  Alert,
  Paper,
  FormControlLabel,
  Table,
  TableHead,
  TableRow,
  TableBody,
  tableCellClasses,
  DeleteOutlinedIcon,
  Select,
  MenuItem,
  SearchIcon,
  styled,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  FormControl,
  Typography,
  useScrollTrigger,
  HighlightOffIcon,
  DialogContentText,
  ControlPointOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function UploadEmployeePhoto(props) {
  const { enqueueSnackbar } = useSnackbar();
  //   const [open, setOpen] = React.useState(false);
  const [popupOpen, setPopupOpen] = React.useState({
    add: false,
    remove: false,
  });
  const isSmallScreen = useMediaQuery("(max-width:981px)");
  const [hitApi, setHitApi] = React.useState(false);
  const [formData, setFormData] = React.useState({
    code: "",
    first_name: "",
    contractor: "",
    employee_type: "",
    company: "",
    employee_status: "",
    department: "",
    photo_type: "",
    removed_photo: "",
  });
  const [rows, setRows] = React.useState([]);
  const [inputData, setInputData] = React.useState({
    employeeCompany: [],
    employeeDepartment: [],
  });
  const [rowCount, setRowCount] = React.useState(0);
  const [isSearch, setIsSearch] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const getAllInputJobDetail = React.useCallback(async () => {
    try {
      const response = await getAllInputJobDetailData();

      if (response.status) {
        const { data } = response;
        const stateFields = [
          "employeeCompany",
          "employeeContractorSubBusiness",
          "employeeDepartment",
          "employeeDesignatedPosition",
          "employeeGrade",
          "employeeGroup",
          "employeeLegalEntity",
          "employeeProcessType",
          "employeeQualification",
          "employeeSkill",
          "employeeTargetGroup",
          "employeeTypeGroup",
          "employeeWorkLocation",
        ];

        const stateData = {};
        stateFields.forEach((field) => {
          stateData[field] = data[field] || [];
        });

        setInputData(stateData);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (err) {
      console.error("Error fetching employment grades:", err);
    }
  }, [enqueueSnackbar]);
  const getRows = React.useCallback(async () => {
    try {
      const data = {
        page: pagination.pageIndex + 1,
        per_page: pagination.pageSize,
        first_name: formData.first_name,
        employee_code: formData.code,
      };
      const response = await SendGetRequest("employeeDocumentUploadUrl", data);
      if (response.status) {
        const { data, count } = response?.data;
        setRows(data);
        setRowCount(count);
      }
      // setRows(dummyData);
      //
    } catch (err) {
      console.error("Error fetching employment grades:", err);
    }
  }, [isSearch,hitApi, pagination.pageIndex, pagination.pageSize]);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  const imageFormData = new FormData();
  const handleClickOpen = (type) => {
    setPopupOpen({ ...popupOpen, [type]: true });
  };
  const handleClose = (type) => {
    setPopupOpen({ ...popupOpen, [type]: false });
  };
  const openDialog = popupOpen.add ? "Upload" : "Remove";
  const openDialogText = `Please select which photo you would like to ${openDialog}.`;
  const openDialogTitle = `${openDialog} Photo`;
  const openDialogButtonOnClick = popupOpen.add
    ? () => handleClose("add")
    : () => handleClose("remove");
  const openedPopup = popupOpen.add ? "add" : "remove";
  const FileUploadFunction = async () => {
    try {
      const response = await UploadEmployeeDocument(imageFormData);
      if (response.status) {
        enqueueSnackbar(response.message, { variant: "success" });
        setFormData((prev) => ({ ...prev, photo_type: "" }));
        imageFormData.delete("photo");
        openDialogButtonOnClick();
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };
  const DeleteEmployeePhoto = async () => {
    try {
      const data = {
        image_code: formData.removed_photo,
        image_type: formData.photo_type,
      };
      const response = await DeleteEmployeeDocument(data);
      if (response.status) {
        setFormData((prev) => ({ ...prev, removed_photo: "", photo_type: "" }));
        enqueueSnackbar(response.message, { variant: "success" });
        openDialogButtonOnClick();
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };
  const onSumbitPopup = async (type) => {
    try {
      if (type === "add") {
        if (formData.photo_type === "" || !imageFormData.has("photo")) {
          enqueueSnackbar("Please select photo type and photo", {
            variant: "error",
          });
          return;
        }
        await FileUploadFunction();
      } else {
        if (formData.removed_photo === "" && formData.photo_type === "") {
          enqueueSnackbar("Please select photo type and enter code", {
            variant: "error",
          });
          return;
        }
        await DeleteEmployeePhoto();
      }
      setHitApi(!hitApi);
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };
  React.useEffect(() => {
    getAllInputJobDetail();
    getRows();
  }, [getAllInputJobDetail, getRows]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const renderMenuItems = (data, value) => {
    return data.map((item) => (
      <MenuItem sx={{ ml: 3 }} value={item[value]}>
        {item["name"]}
      </MenuItem>
    ));
  };

  return (
    <Box sx={styles.root}>
      <HideOnScroll {...props}>
        <AppBar
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            mt: { xs: "53px", sm: "64px" },
            zIndex: 1099,
          }}
          elevation={1}
        >
          <Toolbar sx={[styles.h, styles.bgWhite]}>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item xs={12} md={10}>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{ m: 1, fontWeight: "bold" }}
                >
                  Employee Photo
                </Typography>
              </Grid>

              <Grid item xs={12} md={2}>
                <React.Fragment>
                  <Box
                    sx={[
                      styles.bgWhite,
                      styles.wFull,
                      {
                        display: "flex",
                        justifyContent: "end",
                        marginTop: "10px",
                        // marginBottom: "20px",
                      },
                    ]}
                    className="MainPadding"
                  >
                    <React.Fragment>
                      <Button
                        variant="contained"
                        onClick={() => handleClickOpen("add")}
                        sx={
                          isSmallScreen
                            ? { width: "50%", mr: 2 }
                            : { maxWidth: "100%", mr: 2, textTransform: "none" }
                        }
                        startIcon={<ControlPointOutlinedIcon />}
                      >
                        Add
                      </Button>
                    </React.Fragment>
                    <React.Fragment>
                      <Button
                        variant="contained"
                        onClick={() => handleClickOpen("remove")}
                        sx={
                          isSmallScreen
                            ? { width: "50px" }
                            : { maxWidth: "100%", textTransform: "none" }
                        }
                        startIcon={<DeleteOutlinedIcon />}
                      >
                        Remove
                      </Button>
                    </React.Fragment>
                  </Box>
                  <Dialog
                    open={popupOpen[openedPopup]}
                    onClose={openDialogButtonOnClick}
                    PaperProps={{
                      component: "form",
                      onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const email = formJson.email;
                        console.log(email);
                        // openDialogButtonOnClick();
                      },
                    }}
                  >
                    <DialogTitle>{openDialogTitle}</DialogTitle>
                    <DialogContent>
                      {openedPopup === "add" && (
                        <DialogContentText sx={styles.color}>
                          {/* <Alert variant="soft" sx={styles.color} > */}
                          Notes:
                          <br />* Upload 120*120 image for best quality <br /> *
                          Photo selected to be uploaded must be renamed by
                          Employee Code
                          {/* </Alert> */}
                        </DialogContentText>
                      )}
                      <DialogContentText>{openDialogText}</DialogContentText>
                      {/* we have three type signutare, profile and certificate */}

                      <RadioGroup
                        row
                        aria-label="photo"
                        name="photo_type"
                        value={formData.photo}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="signature"
                          control={<Radio />}
                          label="Signature"
                        />
                        <FormControlLabel
                          value="profile"
                          control={<Radio />}
                          label="Profile"
                        />
                        <FormControlLabel
                          value="certificate"
                          control={<Radio />}
                          label="Certificate"
                        />
                      </RadioGroup>

                      {/* here we give option to select image */}
                      <Grid item xs={12} md={12}>
                        {openedPopup === "add" ? (
                          <>
                            <input
                              accept="image/*"
                              id="contained-button-file"
                              type="file"
                              onChange={(event) => {
                                const file = event.target.files[0];
                                imageFormData.append("photo", file);
                                imageFormData.append(
                                  "image_type",
                                  formData.photo_type
                                );
                              }}
                            />
                            <label htmlFor="contained-button-file"></label>
                          </>
                        ) : (
                          <>
                            <DialogContentText>
                              Enter Code To Remove Photo
                            </DialogContentText>
                            <TextField
                              required
                              id="filled-search"
                              fullWidth
                              size="small"
                              onChange={handleChange}
                              name="removed_photo"
                              value={formData.removed_photo}
                              placeholder="Code"
                            />
                          </>
                        )}
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={openDialogButtonOnClick}>Cancel</Button>
                      <Button
                        variant="contained"
                        component="span"
                        onClick={() => onSumbitPopup(openedPopup)}
                      >
                        {openDialog}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </React.Fragment>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Box sx={styles.firstForm}>
        <Box
          sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]}
          className="MainPadding"
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <Box sx={[styles.box]}>Code</Box>
              <TextField
                required
                id="filled-search"
                fullWidth
                size="small"
                name="code"
                value={formData.code}
                onChange={handleChange}
                placeholder="Code"
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Box sx={[styles.box]}> First Name</Box>
              <TextField
                required
                id="filled-search"
                fullWidth
                size="small"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                placeholder="First Name"
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Contractor</Box>
              <TextField
                required
                id="filled-search"
                fullWidth
                size="small"
                name="contractor"
                value={formData.contractor}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={[styles.box]}> Employee Type</Box>
              <FormControl fullWidth>
                <Select
                  name="employee_type"
                  value={formData.employee_type}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem sx={{ ml: 3 }} value="">
                    <>-Select-</>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Company</Box>
              <FormControl fullWidth>
                <Select
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem sx={{ ml: 3 }} value="">
                    <>-Select-</>
                  </MenuItem>
                  {renderMenuItems(
                    inputData.employeeCompany,
                    "employee_company_id"
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box sx={[styles.box]}> Employee Status</Box>
              <FormControl fullWidth>
                <Select
                  name="employee_status"
                  value={formData.employee_status}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem sx={{ ml: 3 }} value="">
                    <>-Select-</>
                  </MenuItem>
                  <MenuItem sx={{ ml: 3 }} value="active">
                    Active
                  </MenuItem>
                  <MenuItem sx={{ ml: 3 }} value="inActive">
                    In Active
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box sx={[styles.box]}> Department</Box>
              <FormControl fullWidth>
                <Select
                  name="department"
                  value={formData.department}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem sx={{ ml: 3 }} value="">
                    <>-Select-</>
                  </MenuItem>
                  {renderMenuItems(
                    inputData.employeeDepartment,
                    "employee_department_id"
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}></Grid>

            <Grid item xs={12}>
              <Stack
                spacing={2}
                direction="row"
                justifyContent="center"
                sx={styles.marginBottom2}
              >
                <Button
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={() => {
                    setIsSearch(!isSearch);
                  }}
                  startIcon={<SearchIcon />}
                >
                  Search
                </Button>
                <Button
                  variant="outlined"
                  disabled={!isSearch}
                  onClick={() => {
                    setFormData((prev) => ({
                      ...prev,
                      code: "",
                      first_name: "",
                    }));
                    setIsSearch(!isSearch);
                  }}
                  sx={{ textTransform: "none" }}
                  startIcon={<HighlightOffIcon />}
                >
                  Clear
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box sx={styles.p20}>
          <Grid style={{ marginTop: 20 }}>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Code</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Certificate</StyledTableCell>
                    <StyledTableCell>Profile</StyledTableCell>
                    <StyledTableCell>Signature</StyledTableCell>
                  </TableRow>
                </TableHead>
                {rows && (
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.code} sx={styles.TableRow}>
                        <StyledTableCell>{row.code}</StyledTableCell>
                        <StyledTableCell>{row.first_name}</StyledTableCell>
                        <StyledTableCell>
                          {row.certificate ? (
                            <img
                              src={row.certificate}
                              alt="certificate"
                              width="80"
                              height="80"
                            />
                          ) : (
                            "null"
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.profile ? (
                            <img
                              src={row.profile}
                              alt="profile"
                              width="80"
                              height="80"
                            />
                          ) : (
                            "null"
                          )}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.signature ? (
                            <img
                              src={row.signature}
                              alt="signature"
                              width="80"
                              height="80"
                            />
                          ) : (
                            "null"
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <PaginationControls
              pagination={pagination}
              rowCount={rowCount}
              setPagination={setPagination}
            />
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
