import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Grid,
    Stack,
    Slide,
    Button,
    Dialog,
    AppBar,
    Toolbar,
    PropTypes,
    DemoItem,
    DatePicker,
    SearchIcon,
    DialogTitle,
    DialogActions,
    DialogContent,
    AdapterDayjs,
    TextField,
    FormControl,
    Typography,
    useScrollTrigger,
    HighlightOffIcon,
    DialogContentText,
    LocalizationProvider,
    ControlPointOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};


export default function FirstAidTrainingLog(props) {

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const styles = {
        firstForm: {
            padding: '16px 16px 34px 16px',
            backgroundColor: '#f1f1f1'
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom2: {
            marginBottom: 2,
            mt: 2
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        p20: {
            padding: '20px'
        },
        bRadius: {
            borderRadius: '5px',
            marginTop: '45px'
        },
        dFlex: {
            display: 'flex'
        },
        justifySB: {
            justifyContent: 'space-between'
        },
        alignC: {
            alignItems: 'center'
        }
    }

    return (
        <Box sx={styles.root}>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={12} md={11}>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    First Aid Training Log
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={1}>
                                <React.Fragment>
                                    <Button
                                        variant="contained"
                                        onClick={handleClickOpen}
                                        item xs={12} md={1} sx={{ textTransform: 'none', m: 1 }}
                                        startIcon={<ControlPointOutlinedIcon />}>
                                        Add
                                    </Button>
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            component: 'form',
                                            onSubmit: (event) => {
                                                event.preventDefault();
                                                const formData = new FormData(event.currentTarget);
                                                const formJson = Object.fromEntries(formData.entries());
                                                const email = formJson.email;
                                                console.log(email);
                                                handleClose();
                                            },
                                        }}
                                    >
                                        <DialogTitle>Subscribe</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                To subscribe to this website, please enter your email address here. We
                                                will send updates occasionally.
                                            </DialogContentText>
                                            <TextField
                                                autoFocus
                                                required
                                                margin="dense"
                                                id="name"
                                                name="email"
                                                label="Email Address"
                                                type="email"
                                                fullWidth
                                                variant="standard"
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                                            <Button sx={{ textTransform: 'none' }} type="submit">Subscribe</Button>
                                        </DialogActions>
                                    </Dialog>
                                </React.Fragment>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>From Date</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoItem >
                                            <DatePicker />
                                        </DemoItem>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>To Date</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoItem >
                                            <DatePicker />
                                        </DemoItem>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Code Like (Ex. 10, 155 etc.)</Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder="Code Like (Ex. 10, 155 etc.)"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12}>
                            <Stack spacing={2} direction='row' justifyContent='center' sx={styles.marginBottom2}>
                                <Button variant="contained" sx={{ textTransform: 'none' }} startIcon={<SearchIcon />}>Search</Button>
                                <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>Clear</Button>
                            </Stack>
                        </Grid>

                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}