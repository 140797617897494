
import sharedImports from "../../../Comman/sharedImports";
import styles from "./styles";
import { GetHRListing } from "api/HRsection";
import PaginationControls from "component/Comman/paginationControls";
import * as Papa from 'papaparse';
import { saveAs } from 'file-saver';
const {
  React,
  Box,
  Grid,
  Select,
  Paper,
  Table,
  Stack,
  styled,
  Button,
  AppBar,
  Slide,
  Toolbar,
  Checkbox,
  TableRow,
  MenuItem,
  TextField,
  TableBody,
  FormControl,
  DatePicker,
  TableHead,
  DemoItem,
  SearchIcon,
  Typography,
  AdapterDayjs,
  TableContainer,
  TableCell,
  PropTypes,
  tableCellClasses,
  NoteAddIcon,
  useScrollTrigger,
  FormControlLabel,
  HighlightOffIcon,
  SaveAsOutlinedIcon,
  LocalizationProvider,
  UnfoldMoreOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const initalState = {
  Function: "",
  Department: "",
  DepartmentGroup: "",
  Report: "",
  Filter: "",
  Code: "",
  FromDate: null,
  ToDate: null,
};
export default function HRListing(props) {
  const [rows, setRows] = React.useState([]);
  const [state, setState] = React.useState(initalState);
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isApiHit, setIsApiHit] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(0);
  const [isSearched, setIsSearched] = React.useState(false);
  const fetchHRListing = React.useCallback(async () => {
    try {
      const page = pagination.pageIndex + 1;
      const per_page = pagination.pageSize;
      const params = {
        page,
        per_page,
      };
      const response = await GetHRListing(params);
      if (response.status) {
        setRows(response.data.users);
        setRowCount(response.data.total);
      } else {
        console.log(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  }, [pagination]);
  React.useEffect(() => {
    fetchHRListing();
  }, [fetchHRListing, isApiHit, pagination]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleTextChange = (key, value) => {
    console.log("key", key, "value", value);
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const onSearch = async () => {
    try {
      setIsSearched(true);
      const page = pagination.pageIndex + 1;
      const per_page = pagination.pageSize;
      const params = {
        page,
        per_page,
        ...state,
      };
      const response = await GetHRListing(params);
      if (response.status) {

        setRows(response.data.users);
        setRowCount(response.data.total);
      } else {
        console.log(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const ClearFilter = async () => {
    setState(initalState);
    setIsApiHit(!isApiHit);
    setIsSearched(false);
  };

  const handleExport = () => {
    const csv = Papa.unparse(rows);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'hr.csv');
};
const isSearchQueryDisabled = Object.values(state).every( (val) => val === "" || val === null);
  return (
    <>
      <Box sx={styles.root}>
        <HideOnScroll {...props}>
          <AppBar
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
              mt: { xs: "53px", sm: "64px" },
              zIndex: 1099,
            }}
            elevation={1}
          >
            <Toolbar sx={[styles.h, styles.bgWhite]}>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid item>
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{ m: 1, fontWeight: "bold" }}
                  >
                    HR Listing
                  </Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </HideOnScroll>

        <Box sx={[styles.firstForm,{backgroundColor:'#fff'}]}>
          <Grid container spacing={2} sx={{ marginTop: "45px" }}>
            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Function</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Select
                    name="Function"
                    value={state.Function}
                    disabled
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem sx={{ ml: 3 }} value="">
                      <>-Select-</>
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={10}>
                      Ten
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={20}>
                      Twenty
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={30}>
                      Thirty
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Department</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Select
                    name="Department"
                    value={state.Department}
                    disabled
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem sx={{ ml: 3 }} value="">
                      <>-Select-</>
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={10}>
                      Ten
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={20}>
                      Twenty
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={30}>
                      Thirty
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Department Group</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Select
                    name="DepartmentGroup"
                    value={state.DepartmentGroup}
                    disabled
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem sx={{ ml: 3 }} value="">
                      <>-Select-</>
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={10}>
                      Ten
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={20}>
                      Twenty
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={30}>
                      Thirty
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} md={3}></Grid>

            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Code</Box>
              <Grid item xs={12} md={11}>
                <TextField
                  required
                  id="filled-search"
                  fullWidth
                  name="Code"
                  size="small"
                  value={state.Code}
                  onChange={(e) => handleTextChange("Code", e.target.value)}
                  placeholder="Outlined"
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Code Like</Box>
              <Grid item xs={12} md={11}>
                <TextField
                  required
                  id="filled-search"
                  fullWidth
                  size="small"
                  disabled
                  placeholder="Code Like"
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Report</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Select
                    name="Report"
                    value={state.Report}
                    disabled
                    onChange={(e) => handleTextChange("Report", e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem sx={{ ml: 3 }} value="">
                      <>-Select-</>
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={10}>
                      Ten
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={20}>
                      Twenty
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={30}>
                      Thirty
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} md={3}></Grid>

            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>From Date</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                      <DatePicker
                        name="FromDate"
                        value={state.FromDate}
                        onChange={(date) => handleTextChange("FromDate", date)}
                      />
                    </DemoItem>
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>To Date</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoItem>
                      <DatePicker
                        name="ToDate"
                        value={state.ToDate}
                        onChange={(date) => handleTextChange("ToDate", date)}
                      />
                    </DemoItem>
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ fontWeight: "bold" }}>Filter</Box>
              <Grid item xs={12} md={11}>
                <FormControl fullWidth>
                  <Select
                    name="Filter"
                    value={state.Filter}
                    onChange={handleChange}
                    disabled
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem sx={{ ml: 3 }} value="">
                      <>-All-</>
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={10}>
                      Ten
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={20}>
                      Twenty
                    </MenuItem>
                    <MenuItem sx={{ ml: 3 }} value={30}>
                      Thirty
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                value="end"
                control={<Checkbox />}
                label="Temporary Registration"
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2} direction="row" justifyContent="center">
                <Button
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  disabled={isSearchQueryDisabled}
                  startIcon={<SearchIcon />}
                  onClick={onSearch}
                >
                  Search
                </Button>
                <Button
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  disabled={!isSearched}
                  onClick={ClearFilter}
                  startIcon={<HighlightOffIcon />}
                >
                  Clear
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleExport}
                  disabled={rowCount === 0}
                  sx={{ textTransform: "none" }}
                  startIcon={<NoteAddIcon />}
                >
                  Export
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box sx={styles.firstForm}>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow sx={styles.TableRow}>
                  <StyledTableCell>
                    Temporary Code <UnfoldMoreOutlinedIcon />
                  </StyledTableCell>
                  <StyledTableCell>
                    Employee Code <UnfoldMoreOutlinedIcon />
                  </StyledTableCell>
                  <StyledTableCell>
                    Employee Name <UnfoldMoreOutlinedIcon />
                  </StyledTableCell>
                  <StyledTableCell>
                    Department <UnfoldMoreOutlinedIcon />
                  </StyledTableCell>
                  <StyledTableCell>
                    Status <UnfoldMoreOutlinedIcon />
                  </StyledTableCell>
                  <StyledTableCell>
                    Fitness <UnfoldMoreOutlinedIcon />
                  </StyledTableCell>
                  <StyledTableCell>
                    Reason <UnfoldMoreOutlinedIcon />
                  </StyledTableCell>
                  <StyledTableCell>
                    Action
                    <UnfoldMoreOutlinedIcon />
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row) => (
                  <StyledTableRow key={row.temporaryCode} sx={styles.TableRow}>
                    <StyledTableCell> {row.temporaryCode} </StyledTableCell>
                    <StyledTableCell>{row.employeeCode}</StyledTableCell>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>{row.department}</StyledTableCell>
                    <StyledTableCell>{row.status}</StyledTableCell>
                    <StyledTableCell>{row.fitness}</StyledTableCell>
                    <StyledTableCell>{row.reason}</StyledTableCell>
                    <StyledTableCell>
                      <SaveAsOutlinedIcon color="primary" />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationControls
            pagination={pagination}
            rowCount={rowCount}
            setPagination={setPagination}
          />
        </Box>
      </Box>
    </>
  );
}
