import styles from 'css/styles';
import sharedImports from './sharedImports';
const {
    React,
    Grid,
    AppBar,
    Toolbar,
    Typography,
    CssBaseline,
    Button,
    Link,
    AddCircleOutlineOutlinedIcon,
    ArrowBackIcon,
    useNavigate
} = sharedImports;

const drawerWidth = 300;

export const CommanAppBar = ({appBarTitle, showButtons, handleClickOpen, backButtonPath}) => {
    const navigate = useNavigate();
    return(
        <>
        <CssBaseline />
        <AppBar
            sx={{
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
                mt: { xs: '53px', sm: '64px' },
                zIndex: 1099
            }}
            elevation={1}
        >
            <Toolbar sx={[styles.h, styles.bgWhite]}>
                <Grid container>
                    <Grid item xs={12} md={10}>
                        <Typography variant="h6" noWrap component="div" sx={{ fontWeight: 'bold' }}>{appBarTitle}</Typography>
                    </Grid>
                    {showButtons && (
                        <Grid item xs={12} md={2}>
                            <Button variant='contained' startIcon={<AddCircleOutlineOutlinedIcon />} sx={[{ mr: 1, bgcolor: 'gray' }, styles.textTransform]} onClick={handleClickOpen}> Add </Button>
                            <Button variant='outlined' startIcon={<ArrowBackIcon />} sx={styles.textTransform} onClick={() => {navigate(backButtonPath)}}> Back </Button>
                        </Grid>
                    )}
                </Grid>
            </Toolbar>
        </AppBar>
        </>
    )
}

export const CommanAppBarWithAddButton = ({ appBarTitle, handleUrl, handleClickOpen }) => {
    return (
        <>
            <CssBaseline />
            <AppBar
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px}` },
                    mt: { xs: '58px', sm: '64px' },
                    zIndex: 1099
                }}
                elevation={1}
            >
                <Toolbar sx={[styles.h, styles.bgWhite]}>
                    <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Grid item>
                            <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>{appBarTitle}</Typography>
                        </Grid>
                        <Grid item sx={{ m: { xs: '0px', sm: '0px' } }}>
                            {handleUrl ? (
                                <Link to={handleUrl}>
                                    <Button variant='contained' sx={{ textTransform: 'none', m: 1, mb: 2 }} startIcon={<AddCircleOutlineOutlinedIcon />}> Add </Button>
                                </Link>
                            ) : handleClickOpen ? (
                                <Button variant='contained' sx={{ textTransform: 'none', m: 1, mb: 2 }} startIcon={<AddCircleOutlineOutlinedIcon />} onClick={handleClickOpen}> Add </Button>
                            ) : null}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    );
};