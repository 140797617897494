import {CommanAppBar} from '../../../../Comman/commanAppBar';
import sharedImports from '../../../../Comman/sharedImports';
const {
    React,
    Box,
    Grid,
    Link,
    Paper,
    Button,
    IosShareIcon,
} = sharedImports;

const masterSetupItems = [
    { path: 'employee-grade-master', label: 'Grade Master' },
    { path: 'employee-company-master', label: 'Company Master' },
    { path: 'employee-legal-entity-master', label: 'Legal Entity Master' },
    { path: 'employee-qualification-master', label: 'Qualification Master' },
    { path: 'employee-group-master', label: 'Employee Group Master' },
    { path: 'employee-type-group-master', label: 'Employee Type Group Master' },
    { path: 'employee-type-master', label: 'Employee Type Master' },
    { path: 'employee-target-group-master', label: 'Target Group Master' },
    { path: 'employee-sub-type-master', label: 'Employee Sub Type Master' },
    { path: 'employee-department-master', label: 'Employee Department Master' },
    { path: 'employee-contractor-sub-business-master', label: 'Contractor SubBusiness Master' },
    { path: 'employee-process-type-master', label: 'Process Type Master' },
    { path: 'employee-skill-master', label: 'Skill' },
    { path: 'employee-designated-position', label: 'Designated Position' },
    { path: 'employee-work-location', label: 'Work Location' },
];

const MasterSetupItem = ({ path, label }) => (
    <Grid item xs={12} md={3}>
        <Paper elevation={3} sx={{ p: 1 }}>
            <Link to={path}>
                <Button sx={{ fontWeight: 'bold', textTransform: 'none' }} startIcon={<IosShareIcon />}>
                    {label}
                </Button>
            </Link>
        </Paper>
    </Grid>
);

export default function EmployeeMasterSetup() {
    return (
        <>
            <React.Fragment>
                <Box>
                    <CommanAppBar appBarTitle="Employee Master Setup" showButtons={false} />
                    <Box sx={{ mt: 8, mb: 2, mx: 2, p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                {masterSetupItems.map((item, index) => (
                                    <MasterSetupItem key={index} path={item.path} label={item.label} />
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    );
}