import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Alert,
    Slide,
    AppBar,
    Button,
    Toolbar,
    Typography,
    CssBaseline,
    useScrollTrigger,
    styled,
    FileUploadOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
const VisuallyHiddenInput = styled('input')({});

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function ImportPreJoinee(props) {
    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Typography variant="h6" sx={{fontWeight: 'bold'}} noWrap component="div">
                                    Import Pre Joinee
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 7, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Alert severity="info" sx={{ color: 'red', fontWeight: 'bold', fontSize: '16px', bgcolor: 'lightgray' }}>
                            Notes: Text data to be Separated
                            <br />
                            Mandatory Fields: First Name, Date of Birth, Gender & Medical Date
                            <br />
                            Note: Mobile Number more than 10 digits is not acceptable
                        </Alert>
                        <Box component="div" xs={12}>
                            <Box component='div'>
                                <Button sx={{textTransform: 'none'}} href='#text-button'>Download Template</Button>
                            </Box>
                            <Grid spacing={2} xm={12} sm={12} md={12} lg={12} xl={12} sx={{ mb: 2 }}>
                                <VisuallyHiddenInput type='file' />
                                <Button variant='contained' sx={{textTransform: 'none'}} startIcon={<FileUploadOutlinedIcon />}>Import</Button>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}