import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Grid,
    Select,
    Stack,
    Slide,
    AppBar,
    Toolbar,
    Button,
    Typography,
    PropTypes,
    MenuItem,
    TextField,
    FormControl,
    HighlightOffIcon,
    useScrollTrigger,
    RemoveRedEyeOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export default function LocationWiseEmployeeList(props) {

    const [state, setState] = React.useState({
        Report: '',
        ViewMode: '',
        Filter: '',
        EmployeeTypeGroup: '',
        EmployeeType: '',
        EmployeeSubType: '',
        Function: '',
        DepartmentGroup: '',
        Department: '',
        Grade: '',
        Designation: '',
        JobTitle: '',
        Company: '',
        EmployeeGroup: '',
        Contractor: '',
        Gender: '',
        Status: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const styles = {
        firstForm: {
            padding: '16px 16px 34px 16px',
            backgroundColor: '#f1f1f1'
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom2: {
            marginBottom: 2,
            mt: 2
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        p20: {
            padding: '20px'
        },
        bRadius: {
            borderRadius: '5px',
            marginTop: '45px'
        },
        dFlex: {
            display: 'flex'
        },
        justifySB: {
            justifyContent: 'space-between'
        },
        alignC: {
            alignItems: 'center'
        }
    }

    return (
        <Box sx={styles.root}>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Location Wise Employee List
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={1}>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Report</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Report"
                                        value={state.Report}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-Select-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={1}>Blood Group Age Group Wise</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={2}>Blood Group Chart</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={3}>Blood Group List</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={4}>Blood Group Summary</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={5}>Color Vision List</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={6}>Disability Report</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={7}>Employee Contact List</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={8}>Employee Count Locationwise List</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={9}>Employee Dependant List</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Fire Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={11}>Fire Training List</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={12}>First Aid Training</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={13}>Gender Age Group Wise</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={14}>Gender Wise List</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={15}>Gender Wise Summary</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={16}>Marital Status Age Group Wise</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={17}>Marital Status List</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={18}>Marital Status Summary</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={19}>Process Age Group Wise</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>View Mode</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="ViewMode"
                                        value={state.ViewMode}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Filter</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Filter"
                                        value={state.Filter}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Type Group</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="EmployeeTypeGroup"
                                        value={state.EmployeeTypeGroup}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Type</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="EmployeeType"
                                        value={state.EmployeeType}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Sub Type</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="EmployeeSubType"
                                        value={state.EmployeeSubType}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Function</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Function"
                                        value={state.Function}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Department Group</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="DepartmentGroup"
                                        value={state.DepartmentGroup}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Department</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Department"
                                        value={state.Department}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Grade</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Grade"
                                        value={state.Grade}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Designation</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Designation"
                                        value={state.Designation}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Job Title</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="JobTitle"
                                        value={state.JobTitle}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Company</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Company"
                                        value={state.Company}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Group</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="EmployeeGroup"
                                        value={state.EmployeeGroup}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Contractor</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Contractor"
                                        value={state.Contractor}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Employee Code</Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder="Employee Code"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Age From</Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder="Age From"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Age To</Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder="Age To"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Gender</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Gender"
                                        value={state.Gender}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Status</Box>
                            <Grid item xs={12} md={11}>
                                <FormControl fullWidth>
                                    <Select
                                        name="Status"
                                        value={state.Status}
                                        onChange={handleChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem sx={{ ml: 3 }} value="">
                                            <>-All-</>
                                        </MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={10}>Ten</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={20}>Twenty</MenuItem>
                                        <MenuItem sx={{ ml: 3 }} value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={3}></Grid>

                        <Grid item xs={12}>
                            <Stack spacing={2} direction='row' justifyContent='center' sx={styles.marginBottom2}>
                                <Button variant="contained" sx={{ textTransform: 'none' }} startIcon={<RemoveRedEyeOutlinedIcon />}>View Report</Button>
                                <Button variant="outlined" sx={{ textTransform: 'none' }} startIcon={<HighlightOffIcon />}>Clear</Button>
                            </Stack>
                        </Grid>

                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}