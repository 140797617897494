import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { GetEmployeeGrade, InsertCreateEmployeeGrade, SingleList, UpdateEmployeeGrade, DeleteEmployeeGrade } from '../../../../../../api/employee-master-setup/employeeGrade';
import PaginationControls from '../../../../../Comman/paginationControls';
import sharedImports from '../../../../../Comman/sharedImports';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteButton from '../../../../../Comman/DeleteButton/DeleteButton';
import {CommanAppBar} from '../../../../../Comman/commanAppBar';
import styles from 'css/styles';
import PageSizeSelector from '../../../../../Comman/PageSizeSelector';
import { StyledTableCell, StyledTableRow } from 'helper/employee/employeeHelpers';
const {
    React,
    Box,
    Grid,
    Button,
    TextField,
    UnfoldMoreOutlinedIcon,
    TableBody,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Checkbox,
} = sharedImports;

export default function EmployeeGradeMaster() {
    const [data, setData] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    //table state
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        employee_grade_id: '',
        code: '',
        name: '',
        number: '',
        inactive: 0
    });

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    
    const fetchInfo = async () => {
        try {
            const page = pagination.pageIndex + 1;
            const per_page = pagination.pageSize;
            const parmas = {
                page: page,
                per_page: per_page,
                globalFilter: globalFilter,
                sorting: sorting
            }
    
            const response = await GetEmployeeGrade(parmas);
    
            if (response.status) {
                const { data, totalCount } = response?.data;
                setData(data);
                setRowCount(totalCount);
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        }
        catch (err) {
            enqueueSnackbar("Somthing went wrong", { variant: "error" });
        }
    
    }

    const handleSort = (columnId) => {
        setSorting((prevSorting) => {
            const existingSort = prevSorting.find(sort => sort.employee_grade_id === columnId);

            if (existingSort) {
                if (existingSort.desc === false) {
                    return [{ employee_grade_id: columnId, desc: true }];
                } else {
                    return [];
                }
            } else {
                return [{ employee_grade_id: columnId, desc: false }];
            }
        });

        fetchInfo();
    };

    const handleClickOpen = () => {
        setOpen(true);
        setIsEditing(false);
        setFormData('');
    };

    const handleClose = () => {
        setOpen(false);
    };

    const insertUpdateData = async (formData) => {
        try {
            let response;
            if (isEditing) {
                response = await UpdateEmployeeGrade(formData);
            } else {
                const newData = { ...formData };
                response = await InsertCreateEmployeeGrade(newData);
            }
            
            if (response.status) {                
                enqueueSnackbar(response.message, { variant: "success" });
                setOpen(false);
                fetchInfo();
            } else {
                enqueueSnackbar(`${response.message}`, { variant: "warning" });
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        insertUpdateData(formData);
    }

    const SingleListData = async (id) => {
        try {
            const response = await SingleList(id);
            setFormData(response.data);
            setIsEditing(true);
            setOpen(true);
        } catch (error) {
            console.error('Error fetching job data:', error);
        }
    };

    const handleDeleteRow = async (id) => {
        try {
            const response = await DeleteEmployeeGrade(id);
            if (response.status) {
                    enqueueSnackbar(response.message, { variant: "success" });
                    fetchInfo();
            } else {
                enqueueSnackbar(response.message, { variant: "warning" });
            }
        } catch (error) {
            enqueueSnackbar("Something went wrong", { variant: "error" });
        }
    };

    useEffect(() => {
        fetchInfo();
    }, [globalFilter, pagination.pageIndex, pagination.pageSize, sorting])

    return (
        <>
            <React.Fragment>
                <Box>
                    <CommanAppBar appBarTitle="Employee Grade Master" showButtons={true} handleClickOpen={handleClickOpen} backButtonPath="/dashboard/hr/employee-management/employee-master-setup"/>

                    <Dialog
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogTitle>{isEditing ? 'Update' : 'Add'} Employee Grade</DialogTitle>
                        <DialogContent>
                            <TextField
                                required
                                margin="dense"
                                id="code"
                                label="Code"
                                name="code"
                                value={formData.code}
                                onChange={(e) => setFormData(prev => ({ ...prev, code: e.target.value }))}
                                fullWidth
                                sx={{ width: '500px' }}
                                variant="standard"
                            />
                            <TextField
                                required
                                margin="dense"
                                id="name"
                                label="Name"
                                name="name"
                                value={formData.name || ''}
                                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                                fullWidth
                                sx={{ width: '500px' }}
                                variant="standard"
                            />
                            <TextField
                                required
                                margin="dense"
                                id="number"
                                label="Number"
                                name="number"
                                type="number"
                                value={formData.number || ''}
                                onChange={(e) => setFormData(prev => ({ ...prev, number: e.target.value }))}
                                fullWidth
                                sx={{ width: '500px' }}
                                variant="standard"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.inactive === 1}
                                        onChange={(e) => setFormData(prev => ({ ...prev, inactive: e.target.checked ? 1 : 0 }))}
                                        name="inactive"
                                        color="primary"
                                    />
                                }
                                label="Inactive"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                            <Button sx={{ textTransform: 'none' }} type="save" onClick={handleSubmit}>{isEditing ? 'Update' : 'Save'}</Button>
                        </DialogActions>
                    </Dialog>

                    <Box sx={{ mt: 7, mb: 7, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <PageSizeSelector pageSize={pagination.pageSize} setPagination={setPagination} />
                                </Grid>
                                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                    <TextField
                                        id="filled-search"
                                        type="search"
                                        size='small'
                                        value={globalFilter}
                                        onChange={(e) => setGlobalFilter(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.EmployeeMasterSetupTableRow}>
                                            <StyledTableCell onClick={() => handleSort('employee_grade_id')}>
                                                ID
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('name')}>
                                                Name
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('code')}>
                                                Code
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell onClick={() => handleSort('number')}>
                                                Number
                                                <UnfoldMoreOutlinedIcon />
                                            </StyledTableCell>
                                            <StyledTableCell>Action</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rowCount === 0 ? (
                                            <StyledTableRow>
                                                <StyledTableCell colSpan={5} align="center">
                                                    No data found
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ) : (
                                            data.map((row, index) => (
                                                <StyledTableRow key={row.assetTag} sx={styles.EmployeeMasterSetupTableRow}>
                                                    <StyledTableCell>{index + 1}</StyledTableCell>
                                                    <StyledTableCell>{row.name}</StyledTableCell>
                                                    <StyledTableCell>{row.code}</StyledTableCell>
                                                    <StyledTableCell>{row.number}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <EditOutlinedIcon onClick={() => SingleListData(row.employee_grade_id)} />
                                                        <DeleteButton id={row.employee_grade_id} fieldName={row.name} index={row.index} handleDeleteRow={ () => handleDeleteRow(row.employee_grade_id)} titleName="Employee Grade" />
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <PaginationControls
                                pagination={pagination}
                                rowCount={rowCount}
                                setPagination={setPagination}
                            />
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}