import { ApiCallPost, ApiCallGet, ApiCallPut, ApiCallDelete, ApiCallImportEmployeeData } from "./config/ApiCall";
import { ApiConfig } from "./config/ApiConfig";
import qs from "qs";
import { api } from "api/config/axiosInterceptor";

export const GetUser = async (url) => {
  const headers = {
    "content-type": "application/json",
    authorization: `Bearer ${localStorage.getItem("app-token")}`,
  };

  return await ApiCallGet(url, headers);
}

export const InsertCreateUser = async (props) => {

  const { baseUrl, createUser } = ApiConfig;

  const url = baseUrl + createUser;
  const params = {
    "user_ID": props.userID,
    "temporary_registration": props.temporary_registration,
    "temporary_code": props.temporary_code,
    "code": props.code,
    "reference_code": props.reference_code,
    "first_name": props.first_name,
    "middle_name": props.middle_name,
    "last_name": props.last_name,
    "date_of_birth": props.date_of_birth ? props.date_of_birth.format('YYYY-MM-DD') : null,
    "gender": props.gender,
    "employee_status": props.employee_status,
    "work_location": props.work_location,
    "birth_date_unknown": props.birth_date_unknown,
    "email": props.email,
    "username": props.username,
    "phone_number": props.phone_number,
    "user_role": props.user_role,
    "password": props.password,
    "date_of_joining": props.date_of_joining ? props.date_of_joining.format('YYYY-MM-DD') : null,
    "department": props.department,
    "designation": props.designation,
    "employee_type_group": props.employee_type_group,
    "employee_type": props.employee_type,
    "employee_sub_type": props.employee_sub_type,
    "grade": props.grade,
    "company": props.company,
    "contractor_sub_business": props.contractor_sub_business,
    "target_group": props.target_group,
    "process_type": props.process_type,
    "skill": props.skill,
    "employee_group": props.employee_group,
    "employee_group_code": props.employee_group_code,
    "legal_entity": props.legal_entity,
    "job_title": props.job_title,
    "medical_coverage": props.medical_coverage,
    "raw_material_used": props.raw_material_used,
    "hazardous_process": props.hazardous_process,
    "dangerous_process": props.dangerous_process,
    "contractor": props.contractor,
    "active_upto": props.active_upto ? props.active_upto.format('YYYY-MM-DD') : null,
    "epf_number": props.epf_number,
    "esi_number": props.esi_number,
    "date_of_leaving": props.date_of_leaving ? props.date_of_leaving.format('YYYY-MM-DD') : null,
    "reason_for_leaving": props.reason_for_leaving,
    "working_in_shift": props.working_in_shift,
    "reporting_manager": props.reporting_manager ? props.reporting_manager.format('YYYY-MM-DD') : null,
    "job_description": props.job_description,
    "medical_date": props.medical_date ? props.medical_date.format('YYYY-MM-DD') : null,
    "designated_position": props.designated_position,
    "uid_number": props.uid_number,
    "age": props.age === '' ? null : props.age,
    "color_vision": props.color_vision,
    "blood_group": props.blood_group,
    "marital_status": props.marital_status,
    "religion": props.religion,
    "qualification": props.qualification,
    "id_mark_1": props.id_mark_1,
    "id_mark_2": props.id_mark_2,
    "family_doctor": props.family_doctor,
    "family_doctor_contact": props.family_doctor_contact,
    "family_doctor_address_1": props.family_doctor_address_1,
    "family_doctor_address_2": props.family_doctor_address_2,
    "family_doctor_address_3": props.family_doctor_address_3,
    "disability": props.disability,
    "disability_certified": props.disability_certified,
    "first_aid_training": props.first_aid_training,
    "first_aid_training_date": props.first_aid_training_date ? props.first_aid_training_date.format('YYYY-MM-DD') : null,
    "fire_training": props.fire_training,
    "fire_training_date": props.fire_training_date ? props.fire_training_date.format('YYYY-MM-DD') : null,
    "random_first_aid_date": props.random_first_aid_date ? props.random_first_aid_date.format('YYYY-MM-DD') : null,
    "first_aid_training_due_date": props.first_aid_training_due_date ? props.first_aid_training_due_date.format('YYYY-MM-DD') : null,
    "company_phone_no": props.company_phone_no === '' ? null : props.company_phone_no,
    "extension_no": props.extension_no === '' ? null : props.extension_no,
    "company_email": props.company_email,
    "local_address_1": props.local_address_1,
    "local_address_2": props.local_address_2,
    "local_address_3": props.local_address_3,
    "local_city_1": props.local_city_1,
    "local_state_1": props.local_state_1,
    "local_country_1": props.local_country_1,
    "local_pin_code_1": props.local_pin_code_1,
    "local_contact_no_1": props.local_contact_no_1,
    "permanent_address_1": props.permanent_address_1,
    "permanent_address_2": props.permanent_address_2,
    "permanent_address_3": props.permanent_address_3,
    "permanent_city_1": props.permanent_city_1,
    "permanent_state_1": props.permanent_state_1,
    "permanent_country_1": props.permanent_country_1,
    "permanent_pin_code_1": props.permanent_pin_code_1,
    "permanent_contact_no_1": props.permanent_contact_no_1,
    "emergency_address_1": props.emergency_address_1,
    "emergency_address_2": props.emergency_address_2,
    "emergency_address_3": props.emergency_address_3,
    "emergency_city_1": props.emergency_city_1,
    "emergency_state_1": props.emergency_state_1,
    "emergency_country_1": props.emergency_country_1,
    "emergency_pin_code_1": props.emergency_pin_code_1,
    "emergency_contact_no_1": props.emergency_contact_no_1
  };

  const headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };
  const apiHit = await ApiCallPost(url, JSON.stringify(params), headers)
  console.log("api hit", apiHit)
  return apiHit;
};

export const SingleList = async (id) => {
  const { baseUrl, userUrl } = ApiConfig;

  const url =
    baseUrl +
    userUrl + `?id=${id}`

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`,
  };

  return await ApiCallGet(url, headers);
};

export const UpdateUser = async (props) => {

  const { baseUrl, userUrl } = ApiConfig;

  const url =
    baseUrl +
    userUrl + `?id=${props.id}`

  let params = qs.stringify({
    temporary_registration: props.temporary_registration,
    temporary_code: props.temporary_code,
    code: props.code,
    reference_code: props.reference_code,
    first_name: props.first_name,
    middle_name: props.middle_name,
    last_name: props.last_name,
    date_of_birth: props.date_of_birth ? props.date_of_birth.format('YYYY-MM-DD') : null,
    gender: props.gender,
    employee_status: props.employee_status,
    work_location: props.work_location,
    birth_date_unknown: props.birth_date_unknown,
    email: props.email,
    username: props.username,
    user_role: 1,
    password: props.password,
    date_of_joining: props.date_of_joining ? props.date_of_joining.format('YYYY-MM-DD') : null,
    department: props.department,
    designation: props.designation,
    employee_type_group: props.employee_type_group,
    employee_type: props.employee_type,
    employee_sub_type: props.employee_sub_type,
    grade: props.grade,
    company: props.company,
    contractor_sub_business: props.contractor_sub_business,
    target_group: props.target_group,
    process_type: props.process_type,
    skill: props.skill,
    employee_group: props.employee_group,
    employee_group_code: props.employee_group_code,
    legal_entity: props.legal_entity,
    job_title: props.job_title,
    medical_coverage: props.medical_coverage,
    raw_material_used: props.raw_material_used,
    hazardous_process: props.hazardous_process,
    dangerous_process: props.dangerous_process,
    contractor: props.contractor,
    active_upto: props.active_upto ? props.active_upto.format('YYYY-MM-DD') : null,
    epf_number: props.epf_number,
    esi_number: props.esi_number,
    date_of_leaving: props.date_of_leaving,
    reason_for_leaving: props.reason_for_leaving,
    working_in_shift: props.working_in_shift,
    reporting_manager: props.reporting_manager ? props.reporting_manager.format('YYYY-MM-DD') : null,
    job_description: props.job_description,
    medical_date: props.medical_date ? props.medical_date.format('YYYY-MM-DD') : null,
    designated_position: props.designated_position,
    uid_number: props.uid_number,
    age: props.age,
    color_vision: props.color_vision,
    blood_group: props.blood_group,
    marital_status: props.marital_status,
    religion: props.religion,
    qualification: props.qualification,
    id_mark_1: props.id_mark_1,
    id_mark_2: props.id_mark_2,
    family_doctor: props.family_doctor,
    family_doctor_contact: props.family_doctor_contact,
    family_doctor_address_1: props.family_doctor_address_1,
    family_doctor_address_2: props.family_doctor_address_2,
    family_doctor_address_3: props.family_doctor_address_3,
    disability: props.disability,
    disability_certified: props.disability_certified,
    first_aid_training: props.first_aid_training,
    first_aid_training_date: props.first_aid_training_date ? props.first_aid_training_date.format('YYYY-MM-DD') : null,
    fire_training: props.fire_training,
    fire_training_date: props.fire_training_date ? props.fire_training_date.format('YYYY-MM-DD') : null,
    random_first_aid_date: props.random_first_aid_date ? props.random_first_aid_date.format('YYYY-MM-DD') : null,
    first_aid_training_due_date: props.first_aid_training_due_date ? props.first_aid_training_due_date.format('YYYY-MM-DD') : null,
    company_phone_no: props.company_phone_no,
    extension_no: props.extension_no,
    company_email: props.company_email,
    phone_number: props.phone_number,

    /* Address Detail */
    local_address_1: props?.local_address_1,
    local_address_2: props?.local_address_2,
    local_address_3: props?.local_address_3,
    local_city_1: props?.local_city_1,
    local_state_1: props?.local_state_1,
    local_country_1: props?.local_country_1,
    local_pin_code_1: props?.local_pin_code_1 ? props?.local_pin_code_1 : null,
    local_contact_no_1: props?.local_contact_no_1 ? props?.local_contact_no_1 : null,

    permanent_address_1: props?.permanent_address_1,
    permanent_address_2: props?.permanent_address_2,
    permanent_address_3: props?.permanent_address_3,
    permanent_city_1: props?.permanent_city_1,
    permanent_state_1: props?.permanent_state_1,
    permanent_country_1: props?.permanent_country_1,
    permanent_pin_code_1: props?.permanent_pin_code_1 ? props?.permanent_pin_code_1 : null,
    permanent_contact_no_1: props?.permanent_contact_no_1 ? props?.permanent_contact_no_1 : null,

    emergency_address_1: props?.emergency_address_1,
    emergency_address_2: props?.emergency_address_2,
    emergency_address_3: props?.emergency_address_3,
    emergency_city_1: props?.emergency_city_1,
    emergency_state_1: props?.emergency_state_1,
    emergency_country_1: props?.emergency_country_1,
    emergency_pin_code_1: props?.emergency_pin_code_1 ? props?.emergency_pin_code_1 : null,
    emergency_contact_no_1: props?.emergency_contact_no_1 ? props?.emergency_pin_code_1 : null
  });

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallPut(url, params, headers);
};



export const DeleteUser = async (id) => {
  const { baseUrl, userUrl } = ApiConfig;

  const url =
    baseUrl +
    userUrl + `?id=${id}`

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallDelete(url, headers);
};

export const importEmployeeExcelFile = async (formData) => {

  const { baseUrl, importEmployeeExcelFileUrl } = ApiConfig;

  const url = `${baseUrl}${importEmployeeExcelFileUrl}`;

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallImportEmployeeData(url, formData, headers);
};

export const GetEmployeeFamily = async (parmas) => {
  try {
    const { page, per_page, globalFilter, sorting, userID } = parmas;
    function buildQueryString(page, per_page, globalFilter, sorting, userID) {
      const params = [`page=${page}`, `per_page=${per_page}`];

      if (globalFilter) {
        params.push(`globalFilter=${globalFilter}`);
      }
      if (sorting) {
        params.push(`sorting=${JSON.stringify(sorting)}`);
      }
      if (userID) {
        params.push(`userID=${userID}`);
      }
      return params.join("&");
    }
    const query = buildQueryString(page, per_page, globalFilter, sorting, userID);
    const url = `${ApiConfig.listAllEmployeeFamily}?${query}`;

    const res = await api.get(url);
    return res?.data;
  } catch (error) {
    return error;
  }
}

export const InsertCreateFamilyData = async (props) => {
  const url = `${ApiConfig.createEmployeeFamily}`;
  const params = {
    ...props,
    f_date_of_birth: props?.f_date_of_birth ? props.f_date_of_birth.format('YYYY-MM-DD') : null,
  }
  const response = await api.post(url, params);
  return response.data;
};

export const DeleteEmployeeFamily = async (f_id) => {
  const { baseUrl, deleteEmployeeFamily } = ApiConfig;

  const url =
    baseUrl +
    deleteEmployeeFamily + `?rowID=${f_id}`

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallDelete(url, headers);
};

export const SingleListFamily = async (id) => {
  const url = `${ApiConfig.singleList}?id=${id}`;
  const response = await api.get(url);
  return response.data;
}

export const updateFamilyRow = async (props) => {
  const url = `${ApiConfig.updateFamilyRow}?id=${props.id}`;
  const params = {
    ...props,
    f_date_of_birth: props?.f_date_of_birth ? props.f_date_of_birth.format('YYYY-MM-DD') : null,
  }
  const response = await api.put(url, params);
  return response.data;
}

export const GetEmployeeEmployment = async (parmas) => {
  try {
    const { page, per_page, globalFilter, sorting, userID } = parmas;
    function buildQueryString(page, per_page, globalFilter, sorting) {
      const params = [`page=${page}`, `per_page=${per_page}`];

      if (globalFilter) {
        params.push(`globalFilter=${globalFilter}`);
      }
      if (sorting) {
        params.push(`sorting=${JSON.stringify(sorting)}`);
      }
      if (userID) {
        params.push(`userID=${userID}`);
      }
      return params.join("&");
    }
    const query = buildQueryString(page, per_page, globalFilter, sorting, userID);
    const url = `${ApiConfig.listAllEmployeeEmployment}?${query}`;

    const res = await api.get(url);
    return res?.data;
  } catch (error) {
    return error;
  }
}

export const InsertCreateEmploymentData = async (props) => {
  const url = `${ApiConfig.createEmployeeEmployment}`;
  const response = await api.post(url, props);
  return response.data;
};

export const DeleteEmployeeEmployment = async (f_id) => {
  const { baseUrl, deleteEmployeeEmployment } = ApiConfig;

  const url =
    baseUrl +
    deleteEmployeeEmployment + `?rowID=${f_id}`

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallDelete(url, headers);
};

export const SingleListEmployment = async (id) => {
  const url = `${ApiConfig.employmentSingleList}?id=${id}`;
  const response = await api.get(url);
  return response.data;
}

export const updateEmploymentRow = async (props) => {
  const url = `${ApiConfig.updateEmploymentRow}?id=${props.id}`;
  const response = await api.put(url, props);
  return response.data;
}

export const getAllInputJobDetailData = async () => {
  try {
    const url = `${ApiConfig.getAllInputJobDetailData}`;
    const res = await api.get(url);
    return res?.data;
  } catch (error) {
    return error;
  }
}

export const getEmployee = async (emp_id) => {
  try {
    const url = `${ApiConfig.getEmployeeType}?id=${emp_id}`;
    const res = await api.get(url);
    return res?.data;
  } catch (error) {
    return error
  }
}

export const getSubEmployee = async (emp_id, emg_id) => {
  try {
    const url = `${ApiConfig.getSubEmployeeType}?id=${emp_id}&emg_id=${emg_id}`;
    const res = await api.get(url);
    return res?.data;
  } catch (error) {
    return error
  }
}

export const getAllEmployeeTemporaryData = async () => {
  try {
    const url = `${ApiConfig.getAllEmployeeTemporaryData}`;
    const res = await api.get(url);
    return res?.data;
  } catch (error) {
    return error
  }
}

export const saveTemDataToEmployee = async (props) => {
  try {
    const url = `${ApiConfig.saveTemDataToEmployee}`;
    const params = {
      ...props,
      date_of_birth: props?.date_of_birth ? props.date_of_birth.format('YYYY-MM-DD') : null,
      date_of_joining: props?.date_of_joining ? props.date_of_joining.format('YYYY-MM-DD') : null,
    }
    const res = await api.post(url, { params });
    return res?.data;
  } catch (error) {
    console.error('Error fetching temporary data:', error);
    return { error: 'Failed to fetch temporary data.' }; // Return an error object or handle as needed
  }
}

export const DeleteEmployeeTemData = async (f_id) => {
  const { baseUrl, deleteEmployeeTemData } = ApiConfig;

  const url =
    baseUrl +
    deleteEmployeeTemData + `?rowID=${f_id}`

  const headers = {
    'authorization': `Bearer ${localStorage.getItem("app-token")}`
  };

  return await ApiCallDelete(url, headers);
};