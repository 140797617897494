import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    Box,
    Grid,
    Select,
    Paper,
    Table,
    Slide,
    styled,
    Button,
    AppBar,
    Toolbar,
    PropTypes,
    TableRow,
    MenuItem,
    TextField,
    TableBody,
    FormControl,
    TableHead,
    InputLabel,
    SearchIcon,
    Typography,
    useScrollTrigger,
    TableContainer,
    TableCell,
    tableCellClasses,
    FileCopyOutlinedIcon,
    HighlightOffIcon,
    UnfoldMoreOutlinedIcon,
} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const rows = [
    { temporaryCode: 65325421, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 65325421, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 879879879, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 879879879, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 986544, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 986544, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
    { temporaryCode: 986544, employeeCode: null, name: 'palak', department: 'Finance', status: 'Not Started', fitness: 'Pending', reason: null },
];

export default function EmployeeDocumentSearch(props) {

    const [age, setAge] = React.useState('');
    const handleChange = (event, newValue) => {
        setAge(event.target.value);
    };

    const styles = {
        firstForm: {
            padding: '16px 16px 34px 16px',
            backgroundColor: '#f1f1f1'
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom2: {
            marginBottom: 2,
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        p20: {
            padding: '20px'
        },
        bRadius: {
            borderRadius: '5px',
            marginTop: '45px'
        }
    }

    return (
        <Box sx={styles.root}>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[styles.h, styles.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Employee Document Search
                                </Typography>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Code</Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder="Code Like"
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>First Name</Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder="First Name"
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ fontWeight: 'bold' }}>Last Name</Box>
                            <Grid item xs={12} md={11}>
                                <TextField
                                    required
                                    id="filled-search"
                                    fullWidth
                                    size='small'
                                    placeholder="Last Name"
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} sx={{mt: 3}}>
                            <Button variant="contained" startIcon={<SearchIcon />} sx={{ mr: 2, textTransform: 'none', ml: 4 }}>Search</Button>    
                            <Button variant="outlined" startIcon={<HighlightOffIcon />} sx={{textTransform: 'none'}}>Clear</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={styles.firstForm}>
                <Box sx={[styles.bgWhite, styles.wFull, styles.p20, styles.bRadius]} className='MainPadding'>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={3} md={2} lg={2} sx={{ sm: { display: 'block' } }}>
                            <Box sx={{fontWeight: 'bold'}}>Show</Box>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="demo-simple-select-helper-label" size="small">10</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={age}
                                    label="10"
                                    onChange={handleChange}
                                    size="small"
                                >
                                    <MenuItem sx={{ml: 3}} value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem sx={{ml: 3}} value={10}>Ten</MenuItem>
                                    <MenuItem sx={{ml: 3}} value={20}>Twenty</MenuItem>
                                    <MenuItem sx={{ml: 3}} value={30}>Thirty</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2} sx={{ sm: { display: 'block' } }}>
                            <Box sx={{fontWeight: 'bold'}}>entries</Box>
                            <Button
                                variant="outlined"
                                sx={{textTransform: 'none'}}
                                startIcon={<FileCopyOutlinedIcon />}>
                                Export
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} md={6} lg={6} sx={{ sm: { display: 'block' } }}></Grid>
                        <Grid item xs={12} sm={3} md={2} lg={2} sx={{ sm: { display: 'block' } }}>
                            <Box sx={{fontWeight: 'bold'}}>Search: </Box>
                            <TextField size="small" sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item sx={{ minWidth: { xs: 1, sm: 1, xl: 1 }, maxWidth: { md: 1, lg: 1 } }}>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow sx={styles.TableRow}>
                                            <StyledTableCell>Code <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Name <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell>Document <UnfoldMoreOutlinedIcon /></StyledTableCell>
                                            <StyledTableCell><UnfoldMoreOutlinedIcon /></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <StyledTableRow key={row.temporaryCode} sx={styles.TableRow}>
                                                <StyledTableCell> {row.temporaryCode} </StyledTableCell>
                                                <StyledTableCell>{row.employeeCode}</StyledTableCell>
                                                <StyledTableCell>{row.name}</StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    )
}