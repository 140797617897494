export const ApiConfig = {
    baseUrl: 'https://healthcareapi.tripkhabri.com/',
    login: "user/login",
    createUser: "user/user",
    userUrl: "user/user",
    listAllUser: "user/user-list",
    expenseUrl: "expense/expense",
    listAllExpense: "expense/expense-list",
    createExpense: "expense/expense",
    assetMaintenanceList: "asset-maintenance/asset-maintenance-list",
    assetMaintenance: "asset-maintenance/asset-maintenance",
    userRoleUrl: "user-role/role",
    singleUserRoleUrl: "user-role/edit",
    userPermissionUrl: "user-permission/permission",
    listAllEmployeeDepartment: "employee-master-setup/employee-department-list",
    employeeDepartmentUrl: "employee-master-setup/employee-department",
    importEmployeeExcelFileUrl: "user/import-employees",
    listAllEmployeeTypeGroup: "employee-master-setup/employee-type-group-list",
    employeeTypeGroupMain: "employee-master-setup/employee-type-group",
    listAllEmployeeType: "employee-master-setup/employee-type-list",
    employeeTypeMain: "employee-master-setup/employee-type",
    listAllEmployeeSubType: "employee-master-setup/employee-sub-type-list",
    employeeSubTypeMain: "employee-master-setup/employee-sub-type",
    listAllEmployeeGrade: "employee-master-setup/employee-grade-list",
    employeeGradeMain: "employee-master-setup/employee-grade",
    listAllEmployeeCompany: "employee-master-setup/employee-company-list",
    employeeCompanyMain: "employee-master-setup/employee-company",
    listAllEmployeeContractorSubBusiness: "employee-master-setup/employee-contractor-subBusiness-list",
    employeeContractorSubBusinessMain: "employee-master-setup/employee-contractor-subBusiness",
    listAllEmployeeTargetGroup: "employee-master-setup/employee-target-group-list",
    employeeTargetGroupMain: "employee-master-setup/employee-target-group",
    listAllEmployeeProcessType: "employee-master-setup/employee-process-type-list",
    employeeProcessTypeMain: "employee-master-setup/employee-process-type",
    listAllEmployeeSkill: "employee-master-setup/employee-skill-list",
    employeeSkillMain: "employee-master-setup/employee-skill",
    listAllEmployeeGroup: "employee-master-setup/employee-group-list",
    employeeGroupMain: "employee-master-setup/employee-group",
    listAllEmployeeLegalEntity: "employee-master-setup/employee-legal-entity-list",
    employeeLegalEntityMain: "employee-master-setup/employee-legal-entity",
    listAllEmployeeQualification: "employee-master-setup/employee-qualification-list",
    employeeQualificationMain: "employee-master-setup/employee-qualification",
    listAllEmployeeDesignatedPosition: "employee-master-setup/employee-designated-position-list",
    employeeDesignatedPositionMain: "employee-master-setup/employee-designated-position",
    listAllEmployeeWorkLocation: "employee-master-setup/employee-work-location-list",
    employeeWorkLocationMain: "employee-master-setup/employee-work-location",
    importLocation: "employee-master-setup/import-location",
    employeeDocumentUploadUrl: "employee-master-setup/employee-image-upload",
    createEmployeeFamily: "employee-master-setup/employee-family-create",
    listAllEmployeeFamily: "employee-master-setup/list-employee-family",
    deleteEmployeeFamily: "employee-master-setup/employee-family",
    singleList: 'employee-master-setup/employee-family-single-list',
    updateFamilyRow: 'employee-master-setup/employee-family-edit',
    createEmployeeEmployment: "employee-master-setup/employee-employment-create",
    listAllEmployeeEmployment: "employee-master-setup/list-employee-employment",
    deleteEmployeeEmployment: "employee-master-setup/employee-employment",
    employmentSingleList: 'employee-master-setup/employee-employment-single-list',
    updateEmploymentRow: 'employee-master-setup/employee-employment-edit',
    getAllInputJobDetailData: 'user/get-input-job-details',
    listUserByRole: "user/user-list-by-role",
    listEmployeeCustomSearch: "employee-master-setup/employee-custom-search",
    employeeCustomFields: "employee-master-setup/employee-custom-fields",
    getSubEmployeeType: 'user/get-sub-employee-type',
    getEmployeeType: 'user/get-employee-type',
    insertLanguage: 'language/insert-language',
    getLanguage: 'language/get-language',
    getAllEmployeeTemporaryData: 'user/get-all-empl-tem-data',
    saveTemDataToEmployee: 'user/save-tem-data-to-employee',
    deleteEmployeeTemData: 'user/delete-employee-tem-data',
  };
