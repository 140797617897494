import { useEffect, useState } from 'react';
import sharedImports from '../../../Comman/sharedImports';
import {
    InsertCreateUser, UpdateUser, InsertCreateFamilyData, GetEmployeeFamily, DeleteEmployeeFamily, SingleListFamily, updateFamilyRow,
    InsertCreateEmploymentData, GetEmployeeEmployment, DeleteEmployeeEmployment, SingleListEmployment, updateEmploymentRow, getAllInputJobDetailData, getSubEmployee, getEmployee
} from '../../../../api/user';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteButton from 'component/Comman/DeleteButton/DeleteButton';
import PaginationControls from 'component/Comman/paginationControls';
import styles from 'css/styles';


const {
    React,
    Box,
    Tabs,
    Tab,
    Alert,
    Grid,
    Slide,
    Select,
    Button,
    Dialog,
    AppBar,
    Toolbar,
    Checkbox,
    SaveIcon,
    MenuItem,
    TextField,
    PropTypes,
    Typography,
    FormControl,
    DatePicker,
    DialogTitle,
    DemoItem,
    DialogContent,
    DialogActions,
    AdapterDayjs,
    useScrollTrigger,
    DialogContentText,
    DeleteOutlineIcon,
    CancelOutlinedIcon,
    FileCopyOutlinedIcon,
    FormatListBulletedIcon,
    FormControlLabel,
    SaveAsOutlinedIcon,
    LocalizationProvider,
    styled,
    UnfoldMoreOutlinedIcon,
    TableBody,
    TableContainer,
    TableCell,
    Paper,
    tableCellClasses,
    Table,
    TableHead,
    TableRow,
    AddCircleOutlineOutlinedIcon,

} = sharedImports;

const drawerWidth = 300;
function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function EmployeeForm(props) {
    const [formData, setFormData] = React.useState({
        userID: null,
        temporary_registration: false,
        temporary_code: '',
        code: '',
        reference_code: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        date_of_birth: null,
        gender: '',
        employee_status: '',
        work_location: '',
        birth_date_unknown: false,
        date_of_joining: null,
        department: '',
        designation: '',
        employee_type_group: '',
        employee_type: '',
        employee_sub_type: '',
        grade: '',
        company: '',
        contractor_sub_business: '',
        target_group: '',
        process_type: '',
        skill: '',
        employee_group: '',
        employee_group_code: '',
        legal_entity: '',
        job_title: '',
        medical_coverage: '',
        raw_material_used: '',
        hazardous_process: '',
        dangerous_process: '',
        contractor: '',
        active_upto: null,
        epf_number: '',
        esi_number: '',
        reporting_manager: null,
        job_description: '',
        working_in_shift: false,
        reporting_sub_manager: '',
        medical_date: null,
        designated_position: '',
        uid_number: '',
        age: null,
        color_vision: '',
        blood_group: '',
        marital_status: '',
        religion: '',
        qualification: '',
        id_mark_1: '',
        id_mark_2: '',
        family_doctor: '',
        family_doctor_contact: '',
        family_doctor_address_1: '',
        family_doctor_address_2: '',
        family_doctor_address_3: '',
        disability: false,
        disability_certified: false,
        first_aid_training: false,
        first_aid_training_date: null,
        fire_training: false,
        fire_training_date: null,
        random_first_aid_date: null,
        first_aid_training_due_date: null,
        phone_number: '',
        email: '',


        /* Communication Detail */
        company_phone_no: '',
        extension_no: '',
        company_email: '',

        /* save address */
        local_address_1: "",
        local_address_2: "",
        local_address_3: "",
        local_city_1: "",
        local_state_1: "",
        local_country_1: "",
        local_pin_code_1: null,
        local_contact_no_1: null,

        permanent_address_1: "",
        permanent_address_2: "",
        permanent_address_3: "",
        permanent_city_1: "",
        permanent_state_1: "",
        permanent_country_1: "",
        permanent_pin_code_1: null,
        permanent_contact_no_1: null,

        emergency_address_1: "",
        emergency_address_2: "",
        emergency_address_3: "",
        emergency_city_1: "",
        emergency_state_1: "",
        emergency_country_1: "",
        emergency_pin_code_1: null,
        emergency_contact_no_1: null,
    });

    const [isEditing, setIsEditing] = useState(false);
    const [id, setID] = useState(null);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleChange = async (event) => {
        const { name, checked, type, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? (checked ? true : false) : value
        }));

        if (name === 'employee_type_group' && value) {
            try {
                const response = await getEmployee(value);
                setEmplyGroupId(value);
                setEmployeeType(response.data)
                setFormData(prevState => ({
                    ...prevState,
                    employee_type: '',
                    employee_sub_type: ''
                }))
                setEmployeeSubType([]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else if (name === 'employee_type_group' && !value) {
            setFormData(prevState => ({
                ...prevState,
                employee_type: '',
                employee_sub_type: ''
            }))
            setEmployeeSubType([]);
            setEmployeeType([]);
        }

        if (name === 'employee_type' && value) {
            try {
                const gID = emplyGroupId;
                const response = await getSubEmployee(value, gID);
                setEmployeeSubType(response.data)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else if (name === 'employee_type' && !value) {
            setEmployeeSubType([]);
            setFormData(prevState => ({
                ...prevState,
                employee_sub_type: ''
            }))
        }
    };

    const getEmployeeByGroupID = async (employee_type_group, employee_type) => {
        const response = await getEmployee(employee_type_group);
        setEmployeeType(response.data);
        const res = await getSubEmployee(employee_type, employee_type_group);
        setEmployeeSubType(res.data)
    }

    /* family functions */
    const [familyData, setFamilyData] = React.useState({
        /* Family data */
        id: null,
        f_name: null,
        relation: "",
        f_blood_group: "",
        f_date_of_birth: null,
        f_age: null,
        f_gender: "",
        in_active: false,
        is_obsolete_medical_recode: false,
    })

    const relations = [
        { value: "mother", label: "Mother" },
        { value: "father", label: "Father" },
        { value: "sister", label: "Sister" },
        { value: "brother", label: "Brother" },
        { value: "son", label: "Son" },
        { value: "daughter", label: "Daughter" },
        { value: "spouse", label: "Spouse" },
        { value: "father-in-law", label: "Father-In-Law" },
        { value: "step-family", label: "Step-Family" },
        { value: "siblings", label: "Siblings" },
        { value: "other", label: "Other" },
    ];

    const religions = [
        { value: "hinduism", label: "Hinduism" },
        { value: "christianity", label: "Christianity" },
        { value: "islam", label: "Islam" },
        { value: "buddhism", label: "Buddhism" },
        { value: "sikhism", label: "Sikhism" },
        { value: "jainism", label: "Jainism" },
        { value: "judaism", label: "Judaism" },
        { value: "zoroastrianism", label: "Zoroastrianism" },
        { value: "shinto", label: "Shinto" },
        { value: "taoism", label: "Taoism" },
        { value: "bahai", label: "Bahá'í" },
        { value: "atheism", label: "Atheism" },
        { value: "agnosticism", label: "Agnosticism" },
        { value: "other", label: "Other" }
    ];

    const maritalStatuses = [
        { value: "single", label: "Single" },
        { value: "married", label: "Married" },
        { value: "widowed", label: "Widowed" },
        { value: "divorced", label: "Divorced" },
        { value: "separated", label: "Separated" },
        { value: "in_relationship", label: "In a Relationship" },
        { value: "engaged", label: "Engaged" },
        { value: "domestic_partnership", label: "Domestic Partnership" },
        { value: "civil_union", label: "Civil Union" },
        { value: "other", label: "Other" }
    ];

    const boodGroups = [
        { value: "A+", label: "A+" },
        { value: "A-", label: "A-" },
        { value: "B+", label: "B+" },
        { value: "B-", label: "B-" },
        { value: "AB+", label: "AB+" },
        { value: "AB-", label: "AB-" },
        { value: "O+", label: "O+" },
        { value: "O-", label: "O-" }
    ];

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [familyListData, setfamilyListData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isEditFamily, setIsEditFamily] = useState(false);
    const [openFamilyModel, setOpenFamilyModel] = React.useState(false);


    const getFamilyList = async () => {
        try {

            const page = pagination.pageIndex + 1;
            const per_page = pagination.pageSize;
            const parmas = {
                page: page,
                per_page: per_page,
                globalFilter: globalFilter,
                sorting: sorting,
                userID: id
            }

            const response = await GetEmployeeFamily(parmas);

            if (response.status) {
                const { data, totalCount } = response?.data;
                setfamilyListData(data);
                setRowCount(totalCount);
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        }
        catch (err) {
            console.error('Error fetching employee grades:', err);
        }

    }

    const handleSort = (columnId) => {
        setSorting((prevSorting) => {
            const existingSort = prevSorting.find(sort => sort.id === columnId);

            if (existingSort) {
                if (existingSort.desc === false) {
                    return [{ id: columnId, desc: true }];
                } else {
                    return [];
                }
            } else {
                return [{ id: columnId, desc: false }];
            }
        });

        getFamilyList();
    };

    const updateFamilyData = async (familyData) => {
        try {
            const res = await updateFamilyRow(familyData);
            if (res.status) {
                enqueueSnackbar(res.message, { variant: "success" })
                setOpenFamilyModel(false);
                getFamilyList();
            }
            else {
                enqueueSnackbar(res.message, { variant: "error" });
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    }

    const insertFamilyData = async (familyData) => {
        try {
            const newData = { ...familyData, userID: id };
            const response = await InsertCreateFamilyData(newData);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" })
                setOpenFamilyModel(false);
                getFamilyList();
            } else {
                enqueueSnackbar(response.message, { variant: "error" })
            }
        } catch (error) {
            console.log('====', error);

            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    }

    const handleDeleteFamily = async (id) => {
        try {
            const response = await DeleteEmployeeFamily(id);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                getFamilyList();
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    };

    const handleSubmitFamilyForm = async (e) => {
        e.preventDefault();
        if (isEditFamily) {
            updateFamilyData(familyData);

        } else {
            // Handle create logic
            insertFamilyData(familyData);
        }
    }

    const handleCloseFamily = () => {
        setOpenFamilyModel(false);
    };

    const handleClickOpenFamily = () => {
        setOpenFamilyModel(true);
        setIsEditFamily(false);
        setFamilyData('');
    };

    const handleChangeFamily = (event) => {
        const { name, checked, type, value } = event.target;
        setFamilyData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? (checked ? true : false) : value
        }));
    }

    const handleDateChangeFamily = (date, name) => {
        setFamilyData((prevData) => ({
            ...prevData,
            [name]: date
        }));
    };


    const SingleListFamilyData = async (id) => {
        try {
            const response = await SingleListFamily(id);
            if (response.status) {
                setFamilyData({
                    ...response.data,
                    f_date_of_birth: response?.data?.f_date_of_birth ? dayjs(response?.data?.f_date_of_birth) : null,
                });
                setIsEditFamily(true);
                setOpenFamilyModel(true);
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    };

    /* family end functions */

    /* employement functions */
    const [employmentData, setEmploymentData] = React.useState({
        // Employment data 
        id: null,
        company_name: null,
        duration: null,
        nature_of_work: null,
        exposure_history: null,
    })

    const [employmentPagination, setEmploymentPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [employmentListData, setEmploymentListData] = useState([]);
    const [employmentGlobalFilter, setEmploymentGlobalFilter] = useState('');
    const [employmentSorting, setEmploymentSorting] = useState([]);
    const [employmentRowCount, setEmploymentRowCount] = useState(0);
    const [isEditEmployment, setIsEditEmployment] = useState(false);
    const [openEmploymentModel, setOpenEmploymentModel] = React.useState(false);

    const getEmploymentList = async () => {
        try {

            const page = pagination.pageIndex + 1;
            const per_page = pagination.pageSize;
            const parmas = {
                page: page,
                per_page: per_page,
                globalFilter: employmentGlobalFilter,
                sorting: employmentSorting,
                userID: id
            }

            const response = await GetEmployeeEmployment(parmas);

            if (response.status) {
                const { data, totalCount } = response?.data;
                setEmploymentListData(data);
                setEmploymentRowCount(totalCount);
            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        }
        catch (err) {
            console.error('Error fetching employment grades:', err);
        }

    }

    const handleEmploymentSort = (columnId) => {
        setEmploymentSorting((prevSorting) => {
            const existingSort = prevSorting.find(sort => sort.id === columnId);

            if (existingSort) {
                if (existingSort.desc === false) {
                    return [{ id: columnId, desc: true }];
                } else {
                    return [];
                }
            } else {
                return [{ id: columnId, desc: false }];
            }
        });

        getEmploymentList();
    };

    const updateEmploymentData = async (familyData) => {
        try {
            const res = await updateEmploymentRow(familyData);
            if (res.status) {
                enqueueSnackbar(res.message, { variant: "success" })
                setOpenEmploymentModel(false);
                getEmploymentList();
            }
            else {
                enqueueSnackbar(res.message, { variant: "error" });
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    }

    const insertEmploymentData = async (employmentData) => {
        try {
            const newData = { ...employmentData, userID: id };
            const response = await InsertCreateEmploymentData(newData);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" })
                setOpenEmploymentModel(false);
                getEmploymentList();
            } else {
                enqueueSnackbar(response.message, { variant: "error" })
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    }

    const handleDeleteEmployment = async (id) => {
        try {
            const response = await DeleteEmployeeEmployment(id);
            if (response.status) {
                enqueueSnackbar(response.message, { variant: "success" });
                getEmploymentList();
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    };

    const handleSubmitEmploymentForm = async (e) => {
        e.preventDefault();
        if (isEditEmployment) {
            updateEmploymentData(employmentData);

        } else {
            // Handle create logic
            insertEmploymentData(employmentData);
        }
    }

    const handleCloseEmployment = () => {
        setOpenEmploymentModel(false);
    };

    const handleClickOpenEmployment = () => {
        setOpenEmploymentModel(true);
        setIsEditEmployment(false);
        setEmploymentData('');
    };

    const handleChangeEmployment = (event) => {
        const { name, checked, type, value } = event.target;
        setEmploymentData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? (checked ? true : false) : value
        }));
    }

    const SingleListEmploymentData = async (id) => {
        try {
            const response = await SingleListEmployment(id);
            if (response.status) {
                setEmploymentData({
                    ...response.data,
                });
                setIsEditEmployment(true);
                setOpenEmploymentModel(true);
            }
        } catch (error) {
            enqueueSnackbar('Something went wrong', { variant: 'error' });
        }
    };

    /* employement end functions */

    const handleDateChange = (date, name) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: date
        }));
    };

    const insertData = async (formData) => {
        console.log('formData', formData);

        try {
            const newData = { ...formData };
            const response = await InsertCreateUser(newData);
            const userID = response.data.userId ? response.data.userId : null;
            switch (response.status) {
                case "success":
                    setID(userID)
                    enqueueSnackbar(response.message, { variant: "success" })
                    navigate('/dashboard/hr/employee-management/employee-all-detail');
                    break;
                case "failed":
                    enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
                    break;
                case "error":
                    enqueueSnackbar(response.message, { variant: "error" });
                    break;
                default:
                    enqueueSnackbar('Something went wrong', { variant: 'error' });
                    break;
            }
        } catch (error) {
            console.log('An error occurred', error);
        }
    };

    const updateData = async (formData) => {
        try {
            const response = await UpdateUser(formData);
            switch (response.status) {
                case "success":
                    enqueueSnackbar(response.message, { variant: "success" })
                    navigate('/dashboard/hr/employee-management/employee-all-detail');
                    break;
                case "failed":
                    enqueueSnackbar(`${response.message} is required`, { variant: "warning" });
                    break;
                case "error":
                    enqueueSnackbar(response.message, { variant: "error" });
                    break;
                default:
                    enqueueSnackbar('Something went wrong', { variant: 'error' });
                    break;
            }
        } catch (error) {
            console.log('An error occurred', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isEditing) {
            // Handle update logic
            updateData(formData);
        } else {
            // Handle create logic
            insertData(formData);
        }
    }

    const clearAllVal = () => {
        setFormData({
            temporary_registration: false,
            temporary_code: '',
            code: '',
            reference_code: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            date_of_birth: null,
            gender: '',
            employee_status: '',
            work_location: '',
            birth_date_unknown: false,
            date_of_joining: null,
            department: '',
            designation: '',
            employee_type_group: '',
            employee_type: '',
            employee_sub_type: '',
            grade: '',
            company: '',
            contractor_sub_business: '',
            target_group: '',
            process_type: '',
            skill: '',
            employee_group: '',
            employee_group_code: '',
            legal_entity: '',
            job_title: '',
            medical_coverage: '',
            raw_material_used: '',
            hazardous_process: '',
            dangerous_process: '',
            contractor: '',
            active_upto: null,
            epf_number: '',
            esi_number: '',
            reporting_manager: null,
            job_description: '',
            working_in_shift: false,
            reporting_sub_manager: '',
            medical_date: null,
            designated_position: '',
            uid_number: '',
            age: null,
            color_vision: '',
            blood_group: '',
            marital_status: '',
            religion: '',
            qualification: '',
            id_mark_1: '',
            id_mark_2: '',
            family_doctor: '',
            family_doctor_contact: '',
            family_doctor_address_1: '',
            family_doctor_address_2: '',
            family_doctor_address_3: '',
            disability: false,
            disability_certified: false,
            first_aid_training: false,
            first_aid_training_date: null,
            fire_training: false,
            fire_training_date: null,
            random_first_aid_date: null,
            first_aid_training_due_date: null,
            phone_number: '',
            email: '',
    
    
            /* Communication Detail */
            company_phone_no: '',
            extension_no: '',
            company_email: '',
    
            /* save address */
            local_address_1: "",
            local_address_2: "",
            local_address_3: "",
            local_city_1: "",
            local_state_1: "",
            local_country_1: "",
            local_pin_code_1: null,
            local_contact_no_1: null,
    
            permanent_address_1: "",
            permanent_address_2: "",
            permanent_address_3: "",
            permanent_city_1: "",
            permanent_state_1: "",
            permanent_country_1: "",
            permanent_pin_code_1: null,
            permanent_contact_no_1: null,
    
            emergency_address_1: "",
            emergency_address_2: "",
            emergency_address_3: "",
            emergency_city_1: "",
            emergency_state_1: "",
            emergency_country_1: "",
            emergency_pin_code_1: null,
            emergency_contact_no_1: null,
        })
    }
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    useEffect(() => {
        const storedUserId = localStorage.getItem("userID");

        if (storedUserId) {
            setFormData(prevState => ({
                ...prevState,
                userID: storedUserId
            }));
        }
        if (props.userId && props.userData) {
            const employee_type_group = props.userData.jobDetails[0].employee_type_group;
            const employee_type = props.userData.jobDetails[0].employee_type;
            setIsEditing(true);
            getEmployeeByGroupID(employee_type_group, employee_type);
            setFormData({
                ...props.userData,
                id: props.userData.id,
                temporary_registration: props.userData.temporary_registration,
                temporary_code: props.userData.temporary_code,
                code: props.userData.code,
                reference_code: props.userData.reference_code,
                first_name: props.userData.first_name,
                middle_name: props.userData.middle_name,
                last_name: props.userData.last_name,
                date_of_birth: props.userData.date_of_birth ? dayjs(props.userData.date_of_birth) : null,
                gender: props.userData.gender,
                employee_status: props.userData.employee_status,
                work_location: props.userData.work_location ?? "",
                birth_date_unknown: props.userData.birth_date_unknown,
                email: props.userData.email,
                username: props.userData.username,
                phone_number: props.userData.phone_number,
                user_role: props.userData.user_role,
                password: props.userData.password,
                date_of_joining: props.userData.jobDetails[0].date_of_joining ? dayjs(props.userData.jobDetails[0].date_of_joining) : null,
                department: props.userData.jobDetails[0].department,
                designation: props.userData.jobDetails[0].designation,
                employee_type_group: props.userData.jobDetails[0].employee_type_group ?? "",
                employee_type: props.userData.jobDetails[0].employee_type ?? "",
                employee_sub_type: props.userData.jobDetails[0].employee_sub_type ?? "",
                grade: props.userData.jobDetails[0].grade,
                company: props.userData.jobDetails[0].company ?? "",
                contractor_sub_business: props.userData.jobDetails[0].contractor_sub_business ?? "",
                target_group: props.userData.jobDetails[0].target_group,
                process_type: props.userData.jobDetails[0].process_type,
                skill: props.userData.jobDetails[0].skill ?? "",
                employee_group: props.userData.jobDetails[0].employee_group,
                employee_group_code: props.userData.jobDetails[0].employee_group_code,
                legal_entity: props.userData.jobDetails[0].legal_entity ?? "",
                job_title: props.userData.jobDetails[0].job_title ?? "",
                medical_coverage: props.userData.jobDetails[0].medical_coverage ?? "",
                raw_material_used: props.userData.jobDetails[0].raw_material_used,
                hazardous_process: props.userData.jobDetails[0].hazardous_process,
                dangerous_process: props.userData.jobDetails[0].dangerous_process,
                contractor: props.userData.jobDetails[0].contractor,
                active_upto: props.userData.jobDetails[0].active_upto ? dayjs(props.userData.jobDetails[0].active_upto) : null,
                epf_number: props.userData.jobDetails[0].epf_number,
                esi_number: props.userData.jobDetails[0].esi_number,
                date_of_leaving: props.userData.date_of_leaving,
                reason_for_leaving: props.userData.reason_for_leaving,
                working_in_shift: props.userData.jobDetails[0].working_in_shift,
                reporting_manager: props.userData.jobDetails[0].reporting_manager ? dayjs(props.userData.jobDetails[0].reporting_manager) : null,
                job_description: props.userData.jobDetails[0].job_description,
                medical_date: props.userData.jobDetails[0].medical_date ? dayjs(props.userData.jobDetails[0].medical_date) : null,
                designated_position: props.userData.jobDetails[0].designated_position ?? "",
                uid_number: props.userData.personalDetails.uid_number,
                age: props.userData.personalDetails.age,
                color_vision: props.userData.personalDetails.color_vision ?? "",
                blood_group: props.userData.personalDetails.blood_group ?? "",
                marital_status: props.userData.personalDetails.marital_status ?? "",
                religion: props.userData.personalDetails.religion,
                qualification: props.userData.personalDetails.qualification ?? "",
                id_mark_1: props.userData.personalDetails.id_mark_1,
                id_mark_2: props.userData.personalDetails.id_mark_2,
                family_doctor: props.userData.personalDetails.family_doctor,
                family_doctor_contact: props.userData.personalDetails.family_doctor_contact,
                family_doctor_address_1: props.userData.personalDetails.family_doctor_address_1,
                family_doctor_address_2: props.userData.personalDetails.family_doctor_address_2,
                family_doctor_address_3: props.userData.personalDetails.family_doctor_address_3,
                disability: props.userData.personalDetails.disability,
                disability_certified: props.userData.personalDetails.disability_certified,
                first_aid_training: props.userData.personalDetails.first_aid_training,
                first_aid_training_date: props.userData.personalDetails.first_aid_training_date ? dayjs(props.userData.personalDetails.first_aid_training_date) : null,
                fire_training: props.userData.personalDetails.fire_training,
                fire_training_date: props.userData.personalDetails.fire_training_date ? dayjs(props.userData.personalDetails.fire_training_date) : null,
                random_first_aid_date: props.userData.personalDetails.random_first_aid_date ? dayjs(props.userData.personalDetails.random_first_aid_date) : null,
                first_aid_training_due_date: props.userData.personalDetails.first_aid_training_due_date ? dayjs(props.userData.personalDetails.first_aid_training_due_date) : null,

                /* Communication Detail */
                company_phone_no: props.userData.communicationDetails.company_phone_no,
                extension_no: props.userData.communicationDetails.extension_no,
                company_email: props.userData.communicationDetails.company_email,
                /* Address Detail */
                local_address_1: props?.userData.addressDetails.local_address_1,
                local_address_2: props?.userData.addressDetails.local_address_2,
                local_address_3: props?.userData.addressDetails.local_address_3,
                local_city_1: props?.userData.addressDetails.local_city_1,
                local_state_1: props?.userData.addressDetails.local_state_1,
                local_country_1: props?.userData.addressDetails.local_country_1,
                local_pin_code_1: props?.userData.addressDetails.local_pin_code_1,
                local_contact_no_1: props?.userData.addressDetails.local_contact_no_1,

                permanent_address_1: props?.userData.addressDetails.permanent_address_1,
                permanent_address_2: props?.userData.addressDetails.permanent_address_2,
                permanent_address_3: props?.userData.addressDetails.permanent_address_3,
                permanent_city_1: props?.userData.addressDetails.permanent_city_1,
                permanent_state_1: props?.userData.addressDetails.permanent_state_1,
                permanent_country_1: props?.userData.addressDetails.permanent_country_1,
                permanent_pin_code_1: props?.userData.addressDetails.permanent_pin_code_1,
                permanent_contact_no_1: props?.userData.addressDetails.permanent_contact_no_1,

                emergency_address_1: props?.userData.addressDetails.emergency_address_1,
                emergency_address_2: props?.userData.addressDetails.emergency_address_2,
                emergency_address_3: props?.userData.addressDetails.emergency_address_3,
                emergency_city_1: props?.userData.addressDetails.emergency_city_1,
                emergency_state_1: props?.userData.addressDetails.emergency_state_1,
                emergency_country_1: props?.userData.addressDetails.emergency_country_1,
                emergency_pin_code_1: props?.userData.addressDetails.emergency_pin_code_1,
                emergency_contact_no_1: props?.userData.addressDetails.emergency_contact_no_1

            });
        }
        getAllInputJobDetail()
    }, [props.userId, props.userData]);

    useEffect(() => {
        if (props.userId && props.userData) {
            setID(props.userId);
        }
        getFamilyList();
    }, [globalFilter, pagination.pageIndex, pagination.pageSize, sorting, id])

    useEffect(() => {
        if (props.userId && props.userData) {
            setID(props.userId);
        }
        getEmploymentList();
    }, [employmentGlobalFilter, employmentPagination.pageIndex, employmentPagination.pageSize, employmentSorting, id])


    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = (newValue) => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleChanges = (event, newValue) => {
        setValue(newValue);
    };
    const stylesData = {
        firstForm: {
            padding: '16px 16px 34px 16px',
            backgroundColor: '#f1f1f1'
        },
        bgWhite: {
            backgroundColor: 'white',
            color: 'black'
        },
        wFull: {
            width: '100%'
        },
        marginBottom2: {
            marginBottom: 2,
        },
        TableRow: {
            th: {
                width: '12.5%',
                svg: {
                    float: 'right',
                    color: '#1976d2'
                },
            },
            td: {
                border: '1px solid lightgray',
            }
        },
        h: {
            minHeight: '40px !important'
        },
        p20: {
            padding: '20px'
        },
        bRadius: {
            borderRadius: '5px',
            marginTop: '45px'
        },
        dFlex: {
            display: 'flex'
        },
        justifySB: {
            justifyContent: 'space-between'
        },
        alignC: {
            alignItems: 'center'
        },
        color: {
            color: 'red'
        },
        fwBold: {
            fontWeight: 'bold'
        }
    }

    /* Get all input values of Job Detail */
    const [inputData, setInputData] = useState({
        employeeCompany: [],
        employeeContractorSubBusiness: [],
        employeeDepartment: [],
        employeeDesignatedPosition: [],
        employeeGrade: [],
        employeeGroup: [],
        employeeLegalEntity: [],
        employeeProcessType: [],
        employeeQualification: [],
        employeeSkill: [],
        employeeTargetGroup: [],
        employeeTypeGroup: [],
        employeeWorkLocation: []
    });
    const [employeeType, setEmployeeType] = useState([])
    const [employeeSubType, setEmployeeSubType] = useState([])
    const [emplyGroupId, setEmplyGroupId] = useState(null)

    const getAllInputJobDetail = async () => {
        try {
            const response = await getAllInputJobDetailData();

            if (response.status) {
                const { data } = response;
                const stateFields = [
                    'employeeCompany',
                    'employeeContractorSubBusiness',
                    'employeeDepartment',
                    'employeeDesignatedPosition',
                    'employeeGrade',
                    'employeeGroup',
                    'employeeLegalEntity',
                    'employeeProcessType',
                    'employeeQualification',
                    'employeeSkill',
                    'employeeTargetGroup',
                    'employeeTypeGroup',
                    'employeeWorkLocation'
                ];

                // Dynamically setting state for each field
                const stateData = {};
                stateFields.forEach(field => {
                    stateData[field] = data[field] || [];
                });

                setInputData(stateData);

            } else {
                enqueueSnackbar(response.message, { variant: "error" });
            }
        }
        catch (err) {
            console.error('Error fetching employment grades:', err);
        }

    }

    const renderMenuItems = (data, value) => {
        return data.map(item => (
            <MenuItem sx={{ ml: 3 }} value={item[value]}>
                {item['name']}
            </MenuItem>
        ));
    };

    const renderEmployeType = (data, value) => {
        return data.map(item => (
            <MenuItem sx={{ ml: 3 }} value={item[value]} >
                {item['name']}
            </MenuItem>
        ));
    };
    /* Get all input values of Job Detail */

    return (
        <Box sx={stylesData.root}>
            <HideOnScroll {...props}>
                <AppBar
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        mt: { xs: '53px', sm: '64px' },
                        zIndex: 1099
                    }}
                    elevation={1}
                >
                    <Toolbar sx={[stylesData.h, stylesData.bgWhite]}>
                        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={12} md={5}>
                                <Typography variant="h6" noWrap component="div" sx={{ m: 1, fontWeight: 'bold' }}>
                                    Employee All Detail
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button variant="contained" sx={{ mr: 2, m: 1, textTransform: 'none' }} startIcon={<SaveIcon />} onClick={handleSubmit}>Save</Button>
                                {/* <Button variant="outlined" sx={{ mr: 2, m: 1, textTransform: 'none' }} disabled startIcon={<SaveAsOutlinedIcon />}>Edit</Button>
                                <Button variant="outlined" sx={{ mr: 2, m: 1, textTransform: 'none' }} disabled startIcon={<DeleteOutlineIcon />}>Delete</Button> */}
                                <Button variant="contained" sx={{ mr: 2, m: 1, textTransform: 'none' }} startIcon={<CancelOutlinedIcon />} onClick={clearAllVal}>Clear</Button>
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <React.Fragment>
                                    <Button
                                        variant="contained"
                                        startIcon={<FormatListBulletedIcon />}
                                        onClick={() => navigate(`/dashboard/hr/employee-management/employee-custom-search`)}
                                        item xs={12} md={1} sx={{ textTransform: 'none', m: 1 }}>
                                        List
                                    </Button>
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            component: 'form',
                                            onSubmit: (event) => {
                                                event.preventDefault();
                                                const formData = new FormData(event.currentTarget);
                                                const formJson = Object.fromEntries(formData.entries());
                                                const email = formJson.email;
                                                console.log(email);
                                                handleClose();
                                            },
                                        }}
                                    >
                                        <DialogTitle>Subscribe</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                To subscribe to this website, please enter your email address here. We
                                                will send updates occasionally.
                                            </DialogContentText>
                                            <TextField
                                                autoFocus
                                                required
                                                margin="dense"
                                                id="name"
                                                name="email"
                                                label="Email Address"
                                                type="email"
                                                fullWidth
                                                variant="standard"
                                            />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button sx={{ textTransform: 'none' }} onClick={handleClose}>Cancel</Button>
                                            <Button sx={{ textTransform: 'none' }} type="submit">Subscribe</Button>
                                        </DialogActions>
                                    </Dialog>
                                </React.Fragment>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <Box sx={stylesData.firstForm}>
                <Box sx={[stylesData.bgWhite, stylesData.wFull, stylesData.p20, stylesData.bRadius]} className='MainPadding'>

                    <Box sx={{ fontWeight: 600 }}>
                        Code: {formData.code} &nbsp;&nbsp;&nbsp; Name: {formData.first_name}
                    </Box>
                    <Box sx={{ maxWidth: { xs: 1, sm: 1 }, bgcolor: 'background.paper', borderBottom: 1, borderColor: 'divider',  mt: 3 }}>
                        <Tabs
                            value={value}
                            onChange={handleChanges}
                            variant="scrollable"
                        >
                            <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="General" />
                            <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Family" />
                            <Tab sx={{ textTransform: 'none', color: '#1976d2' }} label="Employment" />
                        </Tabs>
                    </Box>

                    <Box sx={[stylesData.bgWhite, stylesData.wFull]} className='MainPadding'>
                        <CustomTabPanel value={value} index={0}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        name="temporary_registration"
                                        checked={formData.temporary_registration}
                                        onChange={handleChange}
                                        control={<Checkbox />}
                                        label="Temporary Registration"
                                        labelPlacement="end"
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Temporary Code</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="temporary_code"
                                        value={formData.temporary_code}
                                        onChange={handleChange}
                                        placeholder="Temp Code"
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={[styles.box]}>
                                        Code
                                        <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                    </Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="code"
                                        value={formData.code}
                                        onChange={handleChange}
                                        placeholder="Code"
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Reference Code</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="reference_code"
                                        value={formData.reference_code}
                                        onChange={handleChange}
                                        placeholder="Reference Code"
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>


                                <Grid item xs={12} md={3}>
                                    <Box sx={[styles.box]}> First Name <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                    </Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="first_name"
                                        value={formData.first_name}
                                        onChange={handleChange}
                                        placeholder="First Name"
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Middle Name</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="middle_name"
                                        value={formData.middle_name}
                                        onChange={handleChange}
                                        placeholder="Middle Name"
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Last Name</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="last_name"
                                        value={formData.last_name}
                                        onChange={handleChange}
                                        placeholder="Last Name"
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={[styles.box]}> Date of Birth <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                    </Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker
                                                    value={formData.date_of_birth}
                                                    onChange={(date) => handleDateChange(date, 'date_of_birth')}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={[styles.box]}> Gender <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                    </Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="gender"
                                            value={formData.gender}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value="male">Male</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value="female">Female</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value="other">Others</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={[styles.box]}> Employee Status <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                    </Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="employee_status"
                                            value={formData.employee_status}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value="active">Active</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value="inActive">In Active</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Work Location</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="work_location"
                                            value={formData.work_location}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            {renderMenuItems(inputData.employeeWorkLocation, 'employee_work_location_id')}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        name="birth_date_unknown"
                                        checked={formData.birth_date_unknown}
                                        onChange={handleChange}
                                        control={<Checkbox />}
                                        label="BirthDate Unknown"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12}>
                                    <Alert variant="soft" sx={[stylesData.color, stylesData.fwBold, { backgroundColor: 'lightgray' }]}>Job Detail</Alert>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={[styles.box]}> Date of Joining <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                    </Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker
                                                    value={formData.date_of_joining}
                                                    onChange={(date) => handleDateChange(date, 'date_of_joining')}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={[styles.box]}> Department <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                    </Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="department"
                                            value={formData.department}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            {renderMenuItems(inputData.employeeDepartment, 'employee_department_id')}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={[styles.box]}> Designation <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                    </Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="designation"
                                        value={formData.designation}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={[styles.box]}> Employee Type Group <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                    </Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="employee_type_group"
                                            value={formData.employee_type_group}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            {renderMenuItems(inputData.employeeTypeGroup, 'employee_type_group_id')}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={[styles.box]}> Employee Type <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                    </Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="employee_type"
                                            value={formData.employee_type}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            {renderEmployeType(employeeType, 'employee_type_id')}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={[styles.box]}> Employee Sub Type <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                    </Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="employee_sub_type"
                                            value={formData.employee_sub_type}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            {renderEmployeType(employeeSubType, 'employee_sub_type_id')}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={[styles.box]}> Grade <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                    </Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="grade"
                                            value={formData.grade}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            {renderMenuItems(inputData.employeeGrade, 'employee_grade_id')}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Company</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="company"
                                            value={formData.company}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            {renderMenuItems(inputData.employeeCompany, 'employee_company_id')}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Contractor Sub Business</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="contractor_sub_business"
                                            value={formData.contractor_sub_business}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            {renderMenuItems(inputData.employeeContractorSubBusiness, 'employee_contractor_sub_business_id')}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={[styles.box]}> Target Group <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                    </Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="target_group"
                                            value={formData.target_group}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            {renderMenuItems(inputData.employeeTargetGroup, 'employee_target_group_id')}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={[styles.box]}> Process Type <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                    </Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="process_type"
                                            value={formData.process_type}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            {renderMenuItems(inputData.employeeProcessType, 'employee_process_type_id')}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Skill</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="skill"
                                            value={formData.skill}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            {renderMenuItems(inputData.employeeSkill, 'employee_skill_id')}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={[styles.box]}> Employee Group <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                    </Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="employee_group"
                                            value={formData.employee_group}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            {renderMenuItems(inputData.employeeGroup, 'employee_group_id')}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Employee Group Code</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="employee_group_code"
                                        value={formData.employee_group_code}
                                        onChange={handleChange}
                                        placeholder='Code'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Legal Entity</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="legal_entity"
                                            value={formData.legal_entity}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            {renderMenuItems(inputData.employeeLegalEntity, 'employee_legal_entity_id')}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Job Title</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="job_title"
                                            value={formData.job_title}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value="Senior Developer">Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value="junior Developer">Twenty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Madical Coverage</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="medical_coverage"
                                            value={formData.medical_coverage}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value="Full">Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value="half">Twenty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Row Material Used</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="raw_material_used"
                                        value={formData.raw_material_used}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Hazardous Process</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="hazardous_process"
                                        value={formData.hazardous_process}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Dangerous Process</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="dangerous_process"
                                        value={formData.dangerous_process}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Contractor</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="contractor"
                                        value={formData.contractor}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Active Upto</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker
                                                    value={formData.active_upto}
                                                    onChange={(date) => handleDateChange(date, 'active_upto')}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>EPF Number</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="epf_number"
                                        value={formData.epf_number}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>ESI Number</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="esi_number"
                                        value={formData.esi_number}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Reporting Manager</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker
                                                    value={formData.reporting_manager}
                                                    onChange={(date) => handleDateChange(date, 'reporting_manager')}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Job Description</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="job_description"
                                        value={formData.job_description}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        name="working_in_shift"
                                        onChange={handleChange}
                                        control={<Checkbox />}
                                        label="Working in Shift"
                                        labelPlacement="end"
                                        checked={formData.working_in_shift}

                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Medical Date</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker
                                                    value={formData.medical_date}
                                                    onChange={(date) => handleDateChange(date, 'medical_date')}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Designated Position</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="designated_position"
                                            value={formData.designated_position}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            {renderMenuItems(inputData.employeeDesignatedPosition, 'employee_designated_position_id')}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <Alert variant="soft" sx={[stylesData.color, stylesData.fwBold, { backgroundColor: 'lightgray' }]}>Personal Detail</Alert>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>UID Number</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="uid_number"
                                        value={formData.uid_number}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Age</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="age"
                                        value={formData.age}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Color Vision</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="color_vision"
                                            value={formData.color_vision}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value="Normal">Ten</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value="hard">Twenty</MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value="simple">Thirty</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Blood Group</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="blood_group"
                                            value={formData.blood_group}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            {boodGroups.map((boolGroup, index) => (
                                                <MenuItem sx={{ ml: 3 }} key={index} value={boolGroup.value}>
                                                    {boolGroup.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Marital Status</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="marital_status"
                                            value={formData.marital_status}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            {maritalStatuses.map((relation, index) => (
                                                <MenuItem sx={{ ml: 3 }} key={index} value={relation.value}>
                                                    {relation.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Religion</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="religion"
                                            value={formData.religion}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            {religions.map((relation, index) => (
                                                <MenuItem sx={{ ml: 3 }} key={index} value={relation.value}>
                                                    {relation.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Qualification</Box>
                                    <FormControl fullWidth>
                                        <Select
                                            name="qualification"
                                            value={formData.qualification}
                                            onChange={handleChange}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem sx={{ ml: 3 }} value="">
                                                <>-Select-</>
                                            </MenuItem>
                                            <MenuItem sx={{ ml: 3 }} value="BSc Computer Science">Ten</MenuItem>
                                            {renderMenuItems(inputData.employeeQualification, 'employee_qualification_id')}

                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>ID Mark1</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="id_mark_1"
                                        value={formData.id_mark_1}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>ID Mark2</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="id_mark_2"
                                        value={formData.id_mark_2}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Family Doctor</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="family_doctor"
                                        value={formData.family_doctor}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Family Doctor Contact</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="family_doctor_contact"
                                        value={formData.family_doctor_contact}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Family Doctor Address 1</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="family_doctor_address_1"
                                        value={formData.family_doctor_address_1}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Family Doctor Address 2</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="family_doctor_address_2"
                                        value={formData.family_doctor_address_2}
                                        onChange={handleChange}
                                    />
                                </Grid>


                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Family Doctor Address 3</Box>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                        name="family_doctor_address_3"
                                        value={formData.family_doctor_address_3}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        name="disability"
                                        checked={formData.disability}
                                        onChange={handleChange}
                                        control={<Checkbox />}
                                        label="Disability"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        name="disability_certified"
                                        checked={formData.disability_certified}
                                        onChange={handleChange}
                                        control={<Checkbox />}
                                        label="Disability Certified"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="filled-search"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        name="first_aid_training"
                                        checked={formData.first_aid_training}
                                        onChange={handleChange}
                                        control={<Checkbox />}
                                        label="First Aid Training"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>First Aid Training Date</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker
                                                    value={formData.first_aid_training_date}
                                                    onChange={(date) => handleDateChange(date, 'first_aid_training_date')}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel
                                        name="fire_training"
                                        checked={formData.fire_training}
                                        onChange={handleChange}
                                        control={<Checkbox />}
                                        label="Fire Training"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Fire Training Date</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker
                                                    value={formData.fire_training_date}
                                                    onChange={(date) => handleDateChange(date, 'fire_training_date')}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Random First Aid Date</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker
                                                    value={formData.random_first_aid_date}
                                                    onChange={(date) => handleDateChange(date, 'random_first_aid_date')}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>First Aid Training Due Date</Box>
                                    <FormControl fullWidth>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoItem >
                                                <DatePicker
                                                    value={formData.first_aid_training_due_date}
                                                    onChange={(date) => handleDateChange(date, 'first_aid_training_due_date')}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </DemoItem>
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12}>
                                    <Alert variant="soft" sx={[stylesData.color, stylesData.fwBold, { backgroundColor: 'lightgray' }]}>Communication Detail</Alert>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Company Phone No</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="company_phone_no"
                                        fullWidth
                                        size='small'
                                        name="company_phone_no"
                                        value={formData.company_phone_no}
                                        onChange={handleChange}
                                        type='number'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Extension No</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="extension_no"
                                        fullWidth
                                        size='small'
                                        name="extension_no"
                                        value={formData.extension_no}
                                        onChange={handleChange}
                                        type='number'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Company Email</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="company_email"
                                        fullWidth
                                        size='small'
                                        name="company_email"
                                        value={formData.company_email}
                                        onChange={handleChange}
                                        type='email'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    (Used for getting Email Alert)
                                </Grid>
                                <Grid item sxs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={[styles.box]}>
                                        Personal Email
                                        <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <TextField
                                        id="email"
                                        fullWidth
                                        size='small'
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        type='email'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={[styles.box]}>
                                        Mobile Number
                                        <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="phone_number"
                                        fullWidth
                                        size='small'
                                        name="phone_number"
                                        value={formData.phone_number}
                                        onChange={handleChange}
                                        type='number'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box>(Used for getting SMS Alert)</Box>
                                </Grid>
                                <Grid item xs={12} md={3}></Grid>

                                <Grid item xs={12}>
                                    <Box sx={{ backgroundColor: 'lightgray', p: 1.5, borderRadius: 1 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={3}></Grid>
                                            <Grid item xs={12} md={3}>
                                                <Box>Local Address (LA)</Box>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>Permanent Address <FileCopyOutlinedIcon sx={{ ml: 2, fontSize: 18 }} /></Box>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>Emergency Address <FileCopyOutlinedIcon sx={{ ml: 2, fontSize: 18 }} /></Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Address 1</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="local_address_1"
                                        fullWidth
                                        size='small'
                                        name="local_address_1"
                                        value={formData.local_address_1}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="permanent_address_1"
                                        fullWidth
                                        size='small'
                                        name="permanent_address_1"
                                        value={formData.permanent_address_1}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="emergency_address_1"
                                        fullWidth
                                        size='small'
                                        name='emergency_address_1'
                                        value={formData.emergency_address_1}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Address 2</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="local_address_2"
                                        fullWidth
                                        size='small'
                                        name='local_address_2'
                                        value={formData.local_address_2}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="permanent_address_2"
                                        fullWidth
                                        size='small'
                                        name='permanent_address_2'
                                        value={formData.permanent_address_2}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="emergency_address_2"
                                        fullWidth
                                        size='small'
                                        name='emergency_address_2'
                                        value={formData.emergency_address_2}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Address 3</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="local_address_3"
                                        name='local_address_3'
                                        fullWidth
                                        size='small'
                                        value={formData.local_address_3}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="permanent_address_3"
                                        fullWidth
                                        size='small'
                                        name='permanent_address_3'
                                        value={formData.permanent_address_3}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="emergency_address_3"
                                        fullWidth
                                        size='small'
                                        name='emergency_address_3'
                                        value={formData.emergency_address_3}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>City</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="local_city_1"
                                        fullWidth
                                        size="small"
                                        name="local_city_1"
                                        value={formData.local_city_1}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="permanent_city_1"
                                        fullWidth
                                        size="small"
                                        name="permanent_city_1"
                                        value={formData.permanent_city_1}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="emergency_city_1"
                                        fullWidth
                                        size="small"
                                        name="emergency_city_1"
                                        value={formData.emergency_city_1}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>State</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="local_state_1"
                                        fullWidth
                                        size="small"
                                        name="local_state_1"
                                        value={formData.local_state_1}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="permanent_state_1"
                                        fullWidth
                                        size="small"
                                        name="permanent_state_1"
                                        value={formData.permanent_state_1}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="emergency_state_1"
                                        fullWidth
                                        size="small"
                                        name="emergency_state_1"
                                        value={formData.emergency_state_1}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Country</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="local_country_1"
                                        fullWidth
                                        size="small"
                                        name="local_country_1"
                                        value={formData.local_country_1}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="permanent_country_1"
                                        fullWidth
                                        size="small"
                                        name="permanent_country_1"
                                        value={formData.permanent_country_1}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="emergency_country_1"
                                        fullWidth
                                        size="small"
                                        name="emergency_country_1"
                                        value={formData.emergency_country_1}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Pin Code</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="local_pin_code_1"
                                        fullWidth
                                        size="small"
                                        name="local_pin_code_1"
                                        value={formData.local_pin_code_1}
                                        onChange={handleChange}
                                        type='number'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="permanent_pin_code_1"
                                        fullWidth
                                        size="small"
                                        name="permanent_pin_code_1"
                                        value={formData.permanent_pin_code_1}
                                        onChange={handleChange}
                                        type='number'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="emergency_pin_code_1"
                                        fullWidth
                                        size="small"
                                        name="emergency_pin_code_1"
                                        value={formData.emergency_pin_code_1}
                                        onChange={handleChange}
                                        type='number'
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Contact No</Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="local_contact_no_1"
                                        fullWidth
                                        size="small"
                                        name="local_contact_no_1"
                                        value={formData.local_contact_no_1}
                                        onChange={handleChange}
                                        type='number'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="permanent_contact_no_1"
                                        fullWidth
                                        size="small"
                                        name="permanent_contact_no_1"
                                        value={formData.permanent_contact_no_1}
                                        onChange={handleChange}
                                        type='number'
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        required
                                        id="emergency_contact_no_1"
                                        fullWidth
                                        size="small"
                                        name="emergency_contact_no_1"
                                        value={formData.emergency_contact_no_1}
                                        onChange={handleChange}
                                        type='number'
                                    />
                                </Grid>
                            </Grid>
                        </CustomTabPanel>

                        {/* Family fields */}

                        <CustomTabPanel value={value} index={1}>
                            <Dialog open={openFamilyModel} onClose={handleCloseFamily} fullWidth maxWidth="md">
                                <DialogTitle>Add Details</DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <Box sx={[styles.box]}> Name <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                            </Box>
                                            <TextField
                                                required
                                                id="f_name"
                                                fullWidth
                                                size="small"
                                                name="f_name"
                                                value={familyData.f_name}
                                                onChange={handleChangeFamily}
                                                placeholder="Name"
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <Box sx={[styles.box]}> Relation <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                            </Box>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="relation"
                                                    value={familyData.relation || ""}
                                                    onChange={handleChangeFamily}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value=""><em>-Select-</em></MenuItem>
                                                    {relations.map((relation, index) => (
                                                        <MenuItem sx={{ ml: 3 }} key={index} value={relation.value}>
                                                            {relation.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <Box sx={{ fontWeight: 'bold' }}>Blood Group</Box>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="f_blood_group"
                                                    value={familyData.f_blood_group || ""}
                                                    onChange={handleChangeFamily}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem sx={{ ml: 3 }} value=""><em>-Select-</em></MenuItem>
                                                    {boodGroups.map((boolGroup, index) => (
                                                        <MenuItem sx={{ ml: 3 }} key={index} value={boolGroup.value}>
                                                            {boolGroup.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <Box sx={{ fontWeight: 'bold' }}>Date of Birth</Box>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    value={familyData.f_date_of_birth}
                                                    onChange={(date) => handleDateChangeFamily(date, 'f_date_of_birth')}
                                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <Box sx={{ fontWeight: 'bold' }}>Age</Box>
                                            <TextField
                                                required
                                                id="f_age"
                                                fullWidth
                                                size="small"
                                                name="f_age"
                                                value={familyData.f_age}
                                                onChange={handleChangeFamily}
                                                type="number"
                                                placeholder='Age'
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <Box sx={[styles.box]}> Gender <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                            </Box>
                                            <FormControl fullWidth>
                                                <Select
                                                    name="f_gender"
                                                    value={familyData.f_gender || ""}
                                                    onChange={handleChangeFamily}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem value=""><em>-Select-</em></MenuItem>
                                                    <MenuItem value="male">Male</MenuItem>
                                                    <MenuItem value="female">Female</MenuItem>
                                                    <MenuItem value="other">Other</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={familyData.in_active}
                                                        name="in_active"
                                                        color="primary"
                                                        onChange={handleChangeFamily}
                                                    />
                                                }
                                                label="In Active"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={familyData.is_obsolete_medical_recode}
                                                        name="is_obsolete_medical_recode"
                                                        color="primary"
                                                        onChange={handleChangeFamily}
                                                    />
                                                }
                                                label="isObsoleteMedicalRecode"
                                            />
                                        </Grid>
                                    </Grid>
                                    <DialogActions>
                                        <Grid item xs={12} md={4}>
                                            <Button onClick={handleCloseFamily}>Cancel</Button>
                                            <Button variant="contained" onClick={handleSubmitFamilyForm}>Submit</Button>
                                        </Grid>
                                    </DialogActions>
                                </DialogContent>

                            </Dialog>
                            <Grid item xs={12} md={2}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        variant='contained'
                                        startIcon={<AddCircleOutlineOutlinedIcon />}
                                        sx={[{ mr: 1, bgcolor: 'gray' }, stylesData.textTransform]}
                                        onClick={handleClickOpenFamily}
                                        disabled={!id}
                                    >
                                        Add
                                    </Button>
                                </Box>
                            </Grid>
                            <Box sx={{ mt: 5, mb: 5, mx: 2, bgcolor: 'white', borderRadius: 1 }}>
                                <Box component="div" xs={12}>
                                    <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Grid item sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                                                <Box component='span' sx={{ fontWeight: 'bold' }}>Show </Box>
                                                <select
                                                    id="outlined-number"
                                                    sx={{ width: '9ch' }}
                                                    value={pagination.pageSize}
                                                    onChange={e => setPagination(prev => ({ ...prev, pageSize: Number(e.target.value), pageIndex: 0 }))}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={50}>50</option>
                                                </select>
                                            </Grid>
                                        </Grid>
                                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                            <TextField
                                                id="filled-search"
                                                type="search"
                                                size='small'
                                                value={globalFilter}
                                                onChange={(e) => setGlobalFilter(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <TableContainer component={Paper} sx={{ mb: 2 }}>
                                        <Table aria-label="customized table">
                                            <TableHead>
                                                <TableRow sx={stylesData.TableRow}>
                                                    <StyledTableCell onClick={() => handleSort('relation')}>
                                                        Relation
                                                        <UnfoldMoreOutlinedIcon />
                                                    </StyledTableCell>
                                                    <StyledTableCell onClick={() => handleSort('f_name')}>
                                                        Name
                                                        <UnfoldMoreOutlinedIcon />
                                                    </StyledTableCell>
                                                    <StyledTableCell onClick={() => handleSort('f_blood_group')}>
                                                        Blood Group
                                                        <UnfoldMoreOutlinedIcon />
                                                    </StyledTableCell>
                                                    <StyledTableCell onClick={() => handleSort('f_date_of_birth')}>
                                                        Date of birth
                                                        <UnfoldMoreOutlinedIcon />
                                                    </StyledTableCell>
                                                    <StyledTableCell onClick={() => handleSort('f_age')}>
                                                        Age
                                                        <UnfoldMoreOutlinedIcon />
                                                    </StyledTableCell>
                                                    <StyledTableCell onClick={() => handleSort('f_gender')}>
                                                        Gender
                                                        <UnfoldMoreOutlinedIcon />
                                                    </StyledTableCell>
                                                    <StyledTableCell>Action</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {familyListData.length === 0 ? (
                                                    <StyledTableRow>
                                                        <StyledTableCell colSpan={7} align="center">
                                                            No records found
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ) : (
                                                    familyListData.map((row, index) => (
                                                        <StyledTableRow key={row?.assetTag} sx={stylesData.TableRow}>
                                                            <StyledTableCell>{row?.relation}</StyledTableCell>
                                                            <StyledTableCell>{row?.f_name}</StyledTableCell>
                                                            <StyledTableCell>{row?.f_blood_group}</StyledTableCell>
                                                            <StyledTableCell>{row?.f_date_of_birth}</StyledTableCell>
                                                            <StyledTableCell>{row?.f_age}</StyledTableCell>
                                                            <StyledTableCell>{row?.f_gender}</StyledTableCell>
                                                            <StyledTableCell>
                                                                <EditOutlinedIcon onClick={() => SingleListFamilyData(row.id)} />
                                                                <DeleteButton id={row?.id} fieldName={row?.f_name} index={row?.index} handleDeleteRow={() => handleDeleteFamily(row?.id)} titleName="Employee Family" />
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <PaginationControls
                                        pagination={pagination}
                                        rowCount={rowCount}
                                        setPagination={setPagination}
                                    />
                                </Box>
                            </Box>
                        </CustomTabPanel>

                        {/* Employment */}
                        <CustomTabPanel value={value} index={2}>
                            <Dialog open={openEmploymentModel} onClose={handleCloseEmployment} fullWidth maxWidth="md">
                                <DialogTitle>Add Details</DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={3}>
                                            <Box sx={[styles.box]}> Company Name <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                            </Box>
                                            <TextField
                                                required
                                                id="company_name"
                                                fullWidth
                                                size='small'
                                                name="company_name"
                                                value={employmentData.company_name}
                                                onChange={handleChangeEmployment}
                                                placeholder="Company Names"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box sx={[styles.box]}> Duration <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                            </Box>
                                            <TextField
                                                required
                                                id="duration"
                                                fullWidth
                                                size='small'
                                                name="duration"
                                                value={employmentData.duration}
                                                onChange={handleChangeEmployment}
                                                placeholder="Duration"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box sx={[styles.box]}> Nature of work <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                            </Box>
                                            <TextField
                                                required
                                                id="nature_of_work"
                                                fullWidth
                                                size='small'
                                                name="nature_of_work"
                                                value={employmentData.nature_of_work}
                                                onChange={handleChangeEmployment}
                                                placeholder='Nature of work'
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box sx={[styles.box]}> Exposure History <Typography color="error" sx={{ marginLeft: 0.5 }}>*</Typography>
                                            </Box>
                                            <TextField
                                                required
                                                id="exposure_history"
                                                fullWidth
                                                size='small'
                                                name="exposure_history"
                                                value={employmentData.exposure_history}
                                                onChange={handleChangeEmployment}
                                                placeholder='Exposure History'
                                            />
                                        </Grid>
                                    </Grid>
                                    <DialogActions>
                                        <Grid item xs={12} md={4} mt={4}>
                                            <Button onClick={handleCloseEmployment}>Cancel</Button>
                                            <Button variant="contained" onClick={handleSubmitEmploymentForm}>Submit</Button>
                                        </Grid>
                                    </DialogActions>
                                </DialogContent>

                            </Dialog>
                            <Grid item xs={12} md={2}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        variant='contained'
                                        startIcon={<AddCircleOutlineOutlinedIcon />}
                                        sx={[{ mr: 1, bgcolor: 'gray' }, stylesData.textTransform]}
                                        onClick={handleClickOpenEmployment}
                                        disabled={!id}
                                    >
                                        Add
                                    </Button>
                                </Box>
                            </Grid>
                            <Box sx={{ mt: 5, mb: 5, mx: 2, bgcolor: 'white', borderRadius: 1 }}>
                                <Box component="div" xs={12}>
                                    <Grid container spacing={2} direction='row' xm={12} sm={12} md={12} lg={12} xl={12} justifyContent='space-between' sx={{ mb: 2 }}>
                                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Grid item sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                                                <Box component='span' sx={{ fontWeight: 'bold' }}>Show </Box>
                                                <select
                                                    id="outlined-number"
                                                    sx={{ width: '9ch' }}
                                                    value={employmentPagination.pageSize}
                                                    onChange={e => setEmploymentPagination(prev => ({ ...prev, pageSize: Number(e.target.value), pageIndex: 0 }))}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={50}>50</option>
                                                </select>
                                            </Grid>
                                        </Grid>
                                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box component='span' sx={{ fontWeight: 'bold' }}>Search: </Box>
                                            <TextField
                                                id="filled-search"
                                                type="search"
                                                size='small'
                                                value={employmentGlobalFilter}
                                                onChange={(e) => setEmploymentGlobalFilter(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <TableContainer component={Paper} sx={{ mb: 2 }}>
                                        <Table aria-label="customized table">
                                            <TableHead>
                                                <TableRow sx={stylesData.TableRow}>
                                                    <StyledTableCell onClick={() => handleEmploymentSort('company_name')}>
                                                        Company Name
                                                        <UnfoldMoreOutlinedIcon />
                                                    </StyledTableCell>
                                                    <StyledTableCell onClick={() => handleEmploymentSort('duration')}>
                                                        Duration
                                                        <UnfoldMoreOutlinedIcon />
                                                    </StyledTableCell>
                                                    <StyledTableCell onClick={() => handleEmploymentSort('nature_of_work')}>
                                                        Nature of work
                                                        <UnfoldMoreOutlinedIcon />
                                                    </StyledTableCell>
                                                    <StyledTableCell onClick={() => handleEmploymentSort('exposure_history')}>
                                                        Exposure History
                                                        <UnfoldMoreOutlinedIcon />
                                                    </StyledTableCell>
                                                    <StyledTableCell>Action</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {employmentListData.length === 0 ? (
                                                    <StyledTableRow>
                                                        <StyledTableCell colSpan={5} align="center">
                                                            No records found
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ) : (
                                                    employmentListData.map((row, index) => (
                                                        <StyledTableRow key={row?.assetTag} sx={stylesData.TableRow}>
                                                            <StyledTableCell>{row?.company_name}</StyledTableCell>
                                                            <StyledTableCell>{row?.duration}</StyledTableCell>
                                                            <StyledTableCell>{row?.nature_of_work}</StyledTableCell>
                                                            <StyledTableCell>{row?.exposure_history}</StyledTableCell>
                                                            <StyledTableCell>
                                                                <EditOutlinedIcon onClick={() => SingleListEmploymentData(row.id)} />
                                                                <DeleteButton
                                                                    id={row?.id}
                                                                    fieldName={row?.company_name}
                                                                    index={row?.index}
                                                                    handleDeleteRow={() => handleDeleteEmployment(row?.id)}
                                                                    titleName="Employee Family"
                                                                />
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <PaginationControls
                                        pagination={employmentPagination}
                                        rowCount={employmentRowCount}
                                        setPagination={setEmploymentPagination}
                                    />
                                </Box>
                            </Box>
                        </CustomTabPanel>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}