import { ApiConfig } from '../../api/config/ApiConfig';
import { GetEmployeeTypeGroup } from '../../api/employee-master-setup/employeeTypeGroup';
import { GetEmployeeType } from '../../api/employee-master-setup/employeeType';
import { GetEmployeeGrade } from 'api/employee-master-setup/employeeGrade';
import { enqueueSnackbar } from 'notistack';
import sharedImports from '../../../src/component/Comman/sharedImports';
const {
    styled,
    TableCell,
    tableCellClasses,
    TableRow,
} = sharedImports;

export const fetchEmployeeTypeGroup = async (setEmployeeTypeGroup) => {
    const { baseUrl, listAllEmployeeTypeGroup } = ApiConfig;
    const url = new URL(listAllEmployeeTypeGroup, baseUrl);
    const response = await GetEmployeeTypeGroup(url);

    switch (response.status) {
        case "success":
            const { data } = await response;
            setEmployeeTypeGroup(data);
            break;
        case "failed":
            enqueueSnackbar(response.message, { variant: "error" });
            break;
        default:
            break;
    }
};

export const fetchEmployeeType = async (setEmployeeType) => {
    const { baseUrl, listAllEmployeeType } = ApiConfig;
    const url = new URL(listAllEmployeeType, baseUrl);
    const response = await GetEmployeeType(url);

    switch (response.status) {
        case "success":
            const { data } = await response;
            setEmployeeType(data);
            break;
        case "failed":
            enqueueSnackbar(response.message, { variant: "error" });
            break;
        default:
            break;
    }
};

export const fetchEmployeeSubType = async (setEmployeeType) => {
    const { baseUrl, listAllEmployeeSubType } = ApiConfig;
    const url = new URL(listAllEmployeeSubType, baseUrl);
    const response = await GetEmployeeType(url);

    switch (response.status) {
        case "success":
            const { data } = await response;
            setEmployeeType(data);
            break;
        case "failed":
            enqueueSnackbar(response.message, { variant: "error" });
            break;
        default:
            break;
    }
};

export const fetchEmployeeGrade = async (setEmployeeGrade) => {
    const { baseUrl, listAllEmployeeGrade } = ApiConfig;
    const url = new URL(listAllEmployeeGrade, baseUrl);
    const response = await GetEmployeeGrade(url);

    switch (response.status) {
        case "success":
            const { data } = await response;
            setEmployeeGrade(data);
            break;
        case "failed":
            enqueueSnackbar(response.message, { variant: "error" });
            break;
        default:
            break;
    }
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));