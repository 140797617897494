import axios from "axios";
import { ApiConfig } from "./ApiConfig";
import { buildQueryString } from "../../helper/index";
import { api } from "./axiosInterceptor";
const tag = 'ApiCall';

const ConsoleLogs = (tag, message) => {
    console.log(`${tag}, ${message}`);
}

export const ApiCallPost = async (url, parameters, headers) => {
    try {
        const response = await axios.post(url, parameters, {headers: headers})
        return response.data;
    } catch (error) {
        ConsoleLogs(tag + ', ApiCallPost', `apiDebug, response error: ${JSON.stringify(error)}`,);
    }
}

export const ApiCallGet = async (url, headers) => {
    try {
        const response = await axios.get(url, {headers: headers})
        return response.data;
    } catch (error) {
        ConsoleLogs(tag + ', ApiCallGet', `apiDebug, response error: ${JSON.stringify(error)}`,);
    }
}

export const ApiCallPut = async (url, parameters, headers) => {
    try {
        const response = await axios.put(url, parameters, {headers: headers})
        return response.data;
    } catch (error) {
        ConsoleLogs(tag + ', ApiCallPut', `apiDebug, response error: ${JSON.stringify(error)}`,);
    }
}

export const ApiCallDelete = async (url, headers) => {
    try {
        const response = await axios.delete(url, {headers: headers})
        return response.data;
    } catch (error) {
        ConsoleLogs(tag + ', ApiCallDelete', `apiDebug, response error: ${JSON.stringify(error)}`,);
    }
}

export const ApiCallImportEmployeeData = async (url, formData, headers) => {
    try {
        const response = await axios.post(url, formData, { headers });
        return response.data;
    } catch (error) {
        console.error('Error during API call:', error);
    }
};

// rahul 
export const SendGetRequest = async (key,parmas) => {
    try {
      const query = buildQueryString(parmas);
      const url = ApiConfig[key] + `?${query}`;
      const response = await api.get(url);
      return response.data;
    } catch (err) {
      return err;
    }
  };