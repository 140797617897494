import sharedImports from '../../../Comman/sharedImports';
const {
    React,
    PropTypes,
    Box,
    Grid,
    Slide,
    Alert,
    Select,
    AppBar,
    styled,
    Toolbar,
    TextField,
    MenuItem,
    Checkbox,
    FormControl,
    Typography,
    CssBaseline,
    useScrollTrigger,
    FormControlLabel,
} = sharedImports;

const drawerWidth = 300;
const VisuallyHiddenInput = styled('input')({});

function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const styles = {
    h: {
        minHeight: '40px !important'
    },
    bgWhite: {
        bgcolor: 'white',
        color: 'black'
    }
}

export default function ImportDocument(props) {
    const [state, setState] = React.useState({
        ExaminationType: '',
        StoreLocation: '',
        Round: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <React.Fragment>
                <Box>
                    <CssBaseline />
                    <HideOnScroll {...props}>
                        <AppBar
                            sx={{
                                width: { sm: `calc(100% - ${drawerWidth}px)` },
                                ml: { sm: `${drawerWidth}px` },
                                mt: { xs: '53px', sm: '64px' },
                                zIndex: 1099
                            }}
                            elevation={1}
                        >
                            <Toolbar sx={[styles.h, styles.bgWhite]}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }} noWrap component="div">
                                    Import Document
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </HideOnScroll>
                    <Box sx={{ mt: 7, mb: 2, mx: 2, bgcolor: 'white', p: 3, borderRadius: 1 }}>
                        <Alert severity="info" sx={{ color: 'red', fontWeight: 'bold', fontSize: '16px', bgcolor: 'lightgray', mb: 2 }}>
                            You can import employee's document from here
                            <br />
                            Note : If employee code is not selected, then it is mandatory to name the document file as employee code (33012374) or with underscore (33012374_filename).
                            <br />
                            Note : Inorder to deny multiple document files for a single employee, it is mandatory to check in Deny Multiple Files check box.
                        </Alert>
                        <Box component="div" xs={12}>
                            <Grid container spacing={2} sx={{ mb: 2 }}>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Examination Type <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="ExaminationType"
                                                value={state.ExaminationType}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Consumable</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Store Location <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="StoreLocation"
                                                value={state.StoreLocation}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>-All-</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Uttar Pradesh</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Round <Box component='span' sx={{ color: 'red' }}>*</Box></Box>
                                    <Grid item xs={12} md={11}>
                                        <FormControl fullWidth>
                                            <Select
                                                name="Round"
                                                value={state.Round}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem sx={{ ml: 3 }} value="">
                                                    <>--All--</>
                                                </MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={1}>Consumable</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={2}>Laboratory</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={3}>Medicine</MenuItem>
                                                <MenuItem sx={{ ml: 3 }} value={4}>SETRID-COLD</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box sx={{ fontWeight: 'bold' }}>Employee Code</Box>
                                    <Grid item xs={12} md={11}>
                                        <TextField
                                            required
                                            id="filled-search"
                                            fullWidth
                                            size='small'
                                            placeholder="Employee Code"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <VisuallyHiddenInput type='file' />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControlLabel control={<Checkbox />} label="Deny Multiple Files" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        </>
    )
}