import { CommanAppBar } from 'component/Comman/commanAppBar';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-charts-enterprise";
import "ag-grid-community/styles/ag-theme-quartz.css";
import sharedImports from '../../../Comman/sharedImports';
import { ApiConfig } from "api/config/ApiConfig";

const { React, Box, Grid, useMemo } = sharedImports;

const EmployeeSearch = () => {

    const datasource = {
        getRows(params) {
            console.log("===", JSON.stringify(params.request, null, 1));
            const { startRow, endRow, filterModel, sortModel, rowGroupCols, groupKeys } = params.request;
            const pageSize = endRow - startRow;
            const page = Math.floor(startRow / pageSize) + 1;
    
            let url = `${ApiConfig.baseUrl}${ApiConfig.listEmployeeCustomSearch}?page=${page}&per_page=${pageSize}&`;
            
            // Serialize rowGroupCols to a JSON string
            url += `rowGroupCols=${encodeURIComponent(JSON.stringify(rowGroupCols))}&`;

            // Append group keys to URL
            groupKeys.forEach(key => {
                const encodedKey = encodeURIComponent(key);
                url += `groupKeys=${encodedKey}&`;
            });

            // Append filters to URL
            Object.keys(filterModel).forEach(filter => {
                const filterDetails = filterModel[filter];
                if (filterDetails.filterType === "date") {
                    const dateFrom = filterDetails.dateFrom ? `${filter}From=${filterDetails.dateFrom}&` : '';
                    const dateTo = filterDetails.dateTo ? `${filter}To=${filterDetails.dateTo}&` : '';
                    url += `${filter}Operator=${filterDetails.operator}&${filter}Type=${filterDetails.type}&`;
                    url += dateFrom + dateTo;
                } else {
                    const encodedFilterValue = encodeURIComponent(filterDetails.filter);
                    url += `${filter}=${encodedFilterValue}&`;
                }
            });
            
    
            // Append sorting to URL
            if (sortModel.length) {
                const { colId, sort } = sortModel[0];
                url += `sorting=${colId}&order=${sort}`;
            }
    
            fetch(url, {
                method: 'get',
                headers: {
                    "content-type": "application/json",
                    authorization: `Bearer ${localStorage.getItem("app-token")}`,
                }
            })
            .then(httpResponse => httpResponse.json())
            .then(response => {
                const { data, totalCount } = response?.data;
    
                // Flatten the nested objects
                const allData = data.map(item => {
                    const { employeeDetails, jobDetails, ...restData } = item;
                    return {
                        ...restData,
                        ...employeeDetails,
                        ...jobDetails,
                    };
                });
    
                params.success({
                    rowData: allData,
                    rowCount: totalCount,
                });
            })
            .catch(error => {
                console.error(error);
            });
        }
    };

    const onGridReady = (params) => {
        params.api.setGridOption('serverSideDatasource', datasource);
    };

    const columnDefs = useMemo(() => [
        {
            headerName: "Employee Info",
            children: [
                { headerName: "Temporary Code", field: "temporaryCode", hide: true, enableRowGroup: true },
                { headerName: "Employee Code", field: "employeeCode" },
                { headerName: "Reference Code", field: "referenceCode", hide: true },
                { headerName: "First Name", field: "employeeFirstName", enableRowGroup: true },
                { headerName: "Last Name", field: "employeeLastName", enableRowGroup: true },
                { headerName: "Date of Birth", field: "employeeDateOfBirth", enableRowGroup: true, filter: "agDateColumnFilter", suppressFloatingFilterButton: false },
                { headerName: "Gender", field: "employeeGender", enableRowGroup: true },
                { headerName: "Employee Status", field: "employeeStatus" },
                { headerName: "Location", field: "locationName" },
                { headerName: "Phone Number", field: "phoneNumber", filter: "agNumberColumnFilter" },
            ],
        },
        {
            headerName: "Employee Detail",
            children: [
                { headerName: "UID Number", field: "uid_number", hide: true },
                { headerName: "Age", field: "age" },
                { headerName: "Color Vision", field: "color_vision" },
                { headerName: "Blood Group", field: "employeeBloodGroup", enableRowGroup: true },
                { headerName: "Marital Status", field: "employeeMaritalStatus", hide: true },
                { headerName: "Religion", field: "employeeReligion" },
                { headerName: "Qualification", field: "employeeQualification", hide: true },
                { headerName: "ID Mark 1", field: "id_mark_1", hide: true },
                { headerName: "ID Mark 2", field: "id_mark_2", hide: true },
                { headerName: "Family Doctor", field: "family_doctor", hide: true },
                { headerName: "Family Doctor Contact", field: "family_doctor_contact", hide: true, filter: "agNumberColumnFilter" },
                { headerName: "Family Doctor Address 1", field: "family_doctor_address_1", hide: true },
                { headerName: "Family Doctor Address 2", field: "family_doctor_address_2", hide: true },
                { headerName: "Family Doctor Address 3", field: "family_doctor_address_3", hide: true },
                { headerName: "First Aid Training Date", field: "first_aid_training_date", hide: true, filter: "agDateColumnFilter", suppressFloatingFilterButton: false },
                { headerName: "Fire Training Date", field: "fire_training_date", hide: true, filter: "agDateColumnFilter", suppressFloatingFilterButton: false },
                { headerName: "Random First Aid Date", field: "random_first_aid_date", hide: true, filter: "agDateColumnFilter", suppressFloatingFilterButton: false },
                { headerName: "First Aid Training Due Date", field: "first_aid_training_due_date", hide: true, filter: "agDateColumnFilter", suppressFloatingFilterButton: false },
            ]
        },
        {
            headerName: "Job Detail",
            children: [
                { headerName: "Date of Joining", field: "date_of_joining", filter: "agDateColumnFilter", suppressFloatingFilterButton: false },
                { headerName: "Department Name", field: "departmentName" },
                { headerName: "Designation", field: "employeeDesignation", hide: true },
                { headerName: "Employee Type Group", field: "employeeTypeGroupName", hide: true },
                { headerName: "Employee Type", field: "employeeTypeName", hide: true },
                { headerName: "Employee Sub Type", field: "employeeSubTypeName", hide: true },
                { headerName: "Grade", field: "gradeName" },
                { headerName: "Company Name", field: "companyName" },
                { headerName: "Contractor Sub Business", field: "contractorSubBusinessName", hide: true },
                { headerName: "Target Group", field: "targetGroupName" },
                { headerName: "Process Type", field: "processTypeName" },
                { headerName: "Skill", field: "skillName" },
                { headerName: "Employee Group", field: "groupName" },
                { headerName: "Employee Group Code", field: "employee_group_code" },
                { headerName: "Legal Entity", field: "legalEntityName" },
                { headerName: "Job Title", field: "job_title" },
                { headerName: "Medical Coverage", field: "employeeMedicalCoverage", hide: true },
                { headerName: "Raw Material Used", field: "raw_material_used", hide: true },
                { headerName: "Hazardous Process", field: "hazardous_process", hide: true },
                { headerName: "Dangerous Process", field: "dangerous_process", hide: true },
                { headerName: "Contractor", field: "contractor", hide: true },
                { headerName: "Active Upto", field: "active_upto", hide: true, filter: "agDateColumnFilter", suppressFloatingFilterButton: false },
                { headerName: "EPF Number", field: "epf_number", hide: true },
                { headerName: "ESI Number", field: "esi_number", hide: true },
                { headerName: "Date of Leaving", field: "date_of_leaving", hide: true, filter: "agDateColumnFilter", suppressFloatingFilterButton: false },
                { headerName: "Reason for Leaving", field: "reason_for_leaving", hide: true },
                { headerName: "Reporting Manager", field: "reporting_manager", hide: true },
                { headerName: "Job Description", field: "job_description", hide: true },
                { headerName: "Medical Date", field: "medical_date", hide: true, filter: "agDateColumnFilter", suppressFloatingFilterButton: false },
                { headerName: "Designated Position", field: "designatedPositionName", hide: true },
            ]
        }
    ], []);

    const defaultColDef = useMemo(() => ({
        filter: "agTextColumnFilter",
        floatingFilter: true,
        suppressFloatingFilterButton: true,
        suppressHeaderFilterButton: true,
        sortable: true,
    }), []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            minWidth: 200,
        };
    }, []);

    return (
        <Box>
            <CommanAppBar appBarTitle="Employee Search" showButtons={false} />
            <Box sx={{ mt: 8, mb: 2, mx: 2, p: 3, borderRadius: 1 }}>
                <Box component="div" xs={12}>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                            <div className="ag-theme-quartz" style={{ width: '100%', height: '720px' }}>
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    autoGroupColumnDef={autoGroupColumnDef}
                                    suppressMakeColumnVisibleAfterUnGroup={false}
                                    suppressRowGroupHidesColumns={false}
                                    sideBar={"columns"}
                                    rowGroupPanelShow={"always"}
                                    pagination={true}
                                    paginationPageSize={10}
                                    paginationPageSizeSelector={[10, 25, 50]}
                                    // domLayout='autoHeight'
                                    rowModelType={"serverSide"}
                                    onGridReady={onGridReady}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default EmployeeSearch;